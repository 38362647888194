var axios = require('axios');
var {error_message} = require('@managers/helpers');

const CONFIGURATIONS_PATH = 'remote_configurations';
const USER_CONFIGURATIONS_PATH = 'remote_user_configurations';

export const FeatureFlagDictionary = Object.freeze({
    FERT_TANK_HEIGHT: "Fert tank height",
    WT_PROTOCOL: "wt_protocol",
    EDIT_FARMING_UNIT: "edit_farming_unit",
    PLANNER_EXPORT: "planner_export",
    FARMING_UNIT_SENSORS: "farming_unit_sensors"
    // add other types as needed
});

export var create_new_configuration = (configuration) => {
    return new Promise(function (resolve, reject) {

        configuration.distributions = convertArrayToObject(configuration.distributions);
        configuration.farming_units = convertArrayToObject(configuration.farming_units);
        configuration.environments = convertArrayToObject(configuration.environments);
        configuration.users_email = convertArrayToObject(configuration.users_email);

        axios.post(CONFIGURATIONS_PATH , {
            configuration: configuration
        }).then(function (res) {

            resolve(res.data);

        }).catch(function (e) {

            console.log(error_message(e));
            reject(e);
        });
    });
};

export var update_configuration = (configuration) => {
    return new Promise(function (resolve, reject) {

        configuration.distributions = convertArrayToObject(configuration.distributions);
        configuration.farming_units = convertArrayToObject(configuration.farming_units);
        configuration.environments = convertArrayToObject(configuration.environments);
        configuration.users_email = convertArrayToObject(configuration.users_email);

        axios.put(CONFIGURATIONS_PATH , {
            configuration: configuration
        }).then(function (res) {

            resolve(res.data);

        }).catch(function (e) {

            console.log(error_message(e));
            reject(e);
        });
    });
};

export var delete_configuration = (configuration_id) => {
  return new Promise(function (resolve, reject) {

    axios.delete(CONFIGURATIONS_PATH + "/" + configuration_id , {
    }).then(function (res) {

      resolve("");

    }).catch(function(e) {
      console.log(error_message(e));
      reject(e);
    });
  });
};

export var get_configurations = () => {
  return new Promise(function (resolve, reject) {

    axios.get(CONFIGURATIONS_PATH , {
    }).then(function (res) {

        if (res.data.hasOwnProperty("configurations")) {
            res.data.configurations.forEach(configuration => {
                configuration.distributions = convertObjectToArray(configuration.distributions);
                configuration.farming_units = convertObjectToArray(configuration.farming_units);
                configuration.environments = convertObjectToArray(configuration.environments);
                configuration.users_email = convertObjectToArray(configuration.users_email);
            });
        }
      resolve(res);

    }).catch(function(e) {
      console.log(error_message(e));
      reject(e);
    });
  });
};
export var load_user_configurations = () => {
  return new Promise(function (resolve, reject) {

    axios.get(USER_CONFIGURATIONS_PATH).then(function (res) {
      resolve(res);

    }).catch(function(e) {
      console.log(error_message(e));
      reject(e);
    });
  });
};

// Function to convert array of strings to object
export function convertArrayToObject(arr) {
    if (arr.length === 0 || (arr.length === 1 && arr[0] === '')) return {};

    return arr.reduce((acc, str) => {

        let [key, value] = str.split(":").map(s => s.trim());

        if (!key || typeof value === 'undefined') {
            return acc;
        }

        acc[key] = value.toLowerCase() === 'true';
        return acc;
    }, {});
}

// Function to convert object to array of strings
export function convertObjectToArray(obj) {
    if (Object.keys(obj).length === 0) return [];
    return Object.entries(obj).map(([key, value]) => {
        return `${key} :${value ? 'True' : 'False'}`;
    });
}
// TODO: YAAKKKKK we need to find better way to do this shit without sending all the data and have direct access to redux
export var get_configuration_config = (configuration_data, config_name, current_distribution, current_farming_unit) => {
    if (configuration_data.configuration_data?.hasOwnProperty(config_name)){

        if (configuration_data.configuration_data[config_name].farming_units.hasOwnProperty(current_farming_unit)){
            return configuration_data.configuration_data[config_name].farming_units[current_farming_unit]
        }
        if (configuration_data.configuration_data[config_name].distributions.hasOwnProperty(current_farming_unit)){
            return configuration_data.configuration_data[config_name].distributions[current_farming_unit]
        }
        return configuration_data.configuration_data[config_name].default_value
    }
    return false
};
