var React = require('react');
var {connect} = require('react-redux');
var GraphToggle = require('GraphToggle');
const {useTranslation, withTranslation} = require("react-i18next");
var SensorGraph = require('SensorGraph');
const {useEffect, useState} = require("react");
var GeneralClasses = require('GeneralClasses');
var {unitDisplayName} = require('unitsManager');
var farmingUnitManager = require('farmingUnitManager');
var {getWaterInputData, WATER_INPUT_DATA} = require('accumulatedManager');

const WaterMeterGraphContainer = (props) => {

    const {t} = useTranslation();
    const [waterMeterData, setWaterMeterData] = useState({});
    const [sensor_graph, setSensor_graph] = useState(null);
    const [maxValYAxis, setMaxValYAxis] = useState(0) // this val responsible to determine margin of top-right side menu
    let [waterInputsKeys, setWaterInputsKeys] = useState([...WATER_INPUT_DATA]);
    let [shouldDisplayPlanner, setShouldDisplayPlanner] = useState(false);
    let userUnits = (props.userData && ("length" in props.userData)) ? unitDisplayName(props.userData.length) : "";
    let dataInLoadingState = props.selectedField?.historical_data["wfr_length"] || props.selectedField?.historical_data["precipitation_h"] || props.selectedField?.historical_data["plan_irr"]
    let graphTitleColor = props.field_state === "active" ? "text-grey45" : "text-grey71"

    useEffect(() => {
        if (typeof dataInLoadingState === "undefined" || !dataInLoadingState) {
            return
        }
        loadWaterData(['etc', 'irrigation_precipitation']);
    }, [props.selectedField?.historical_data["wfr_length"], props.selectedField?.historical_data["precipitation_h"], props.selectedField?.historical_data["plan_irr"], dataInLoadingState, loadWaterData]);

    useEffect(() => {
        const displayPlanner = farmingUnitManager.shouldDisplayPlanner(props.selectedFarmingUnit);
        if (displayPlanner !== shouldDisplayPlanner) {
            setShouldDisplayPlanner(displayPlanner);
        }
        if (displayPlanner) {
            setWaterInputsKeys([...WATER_INPUT_DATA]);
        } else if (waterInputsKeys.includes('plan_irr')) {
            setWaterInputsKeys(prevKeys => prevKeys.filter(key => key !== 'plan_irr'));
        }
    }, [props.selectedFarmingUnit])

    useEffect(() => {
        if (sensor_graph) {
            reloadGraph()
        }
    }, [sensor_graph, reloadGraph, waterMeterData])

    const loadWaterData = (waterMeterDataKeys) => {
        if (props?.selectedField) {
            const {newWaterData, maxYAxisVal} = getWaterInputData(props.selectedField, waterMeterDataKeys)
            setWaterMeterData(newWaterData);
            setMaxValYAxis(maxYAxisVal);
        }
    }

    const reloadGraph = () => {
        let dataToDisplay = {};
        sensor_graph.loadData(null, null, undefined, undefined)
        dataInLoadingState = null

        if (waterMeterData["etc"]) {
            dataToDisplay['etc'] = new GeneralClasses.Sensor(
                {
                    id: 'ETc',
                    name: 'ETc',
                    displayName: 'ETc',
                    data: waterMeterData["etc"],
                    color: '#919DB4',
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'ETc');
        }
        if (waterMeterData["irrigation_precipitation"]) {
            dataToDisplay['irrigation_precipitation'] = new GeneralClasses.Sensor(
                {
                    id: 'Irrigation Precipitation',
                    name: 'Irrigation Precipitation',
                    displayName: 'Irrigation + Precipitation',
                    data: waterMeterData["irrigation_precipitation"],
                    color: '#DD776F',
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'Irrigation Precipitation');
        }
        if (waterMeterData["wfr"]) {
            dataToDisplay['wfr'] = new GeneralClasses.Sensor(
                {
                    name: 'Irrigation',
                    displayName: 'Irrigation',
                    data: waterMeterData["wfr"],
                    color: "#CD91CD", // original color '#CD91CD',rgb(246,107,122,0.3)
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'Irrigation');
        }
        if (waterMeterData["precipitation_h"]) {
            dataToDisplay['precipitation_h'] = new GeneralClasses.Sensor(
                {
                    id: 'Precipitations',
                    name: 'Precipitation',
                    displayName: 'Precipitation',
                    data: waterMeterData["precipitation_h"],
                    color: '#94C7F6',
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'Precipitations');
        }
        if (waterMeterData["plan_irr"]) {
            dataToDisplay['past_planned'] = new GeneralClasses.Sensor(
                {
                    name: 'Past Planned irrigation',
                    displayName: 'Planned irrigation',
                    data: waterMeterData["plan_irr"]["past"],
                    color: '#5E70D1',
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'Past Planned irrigation');
            dataToDisplay['future_planned'] = new GeneralClasses.Sensor(
                {
                    name: 'Future Planned irrigation',
                    displayName: 'Planned irrigation',
                    data: waterMeterData["plan_irr"]["future"],
                    color: '#5E70D1',
                    coordinates: {latitude: '23.2222', longitude: '23.2222'},
                    settings: {is_active: true}
                }, 'Future Planned irrigation');
        }
        let temperatureStatistics = {};
        let etcStatistics = {};
        sensor_graph.loadData(dataToDisplay, props.selectedField?.historical_data, 0, props.selectedField?.geo_data.time_zone, temperatureStatistics, etcStatistics);
    }

    const onSelectionChanged = (event, waterData) => {
        let waterMeterDataKeys = Object.keys(waterMeterData).filter(key => waterMeterData[key])
        if (event.target.checked && !waterMeterDataKeys.includes(waterData)) {
            waterMeterDataKeys.push(waterData)
        } else {
            waterMeterDataKeys = waterMeterDataKeys.filter(x => x !== waterData)
        }
        loadWaterData(waterMeterDataKeys);
    }

    const onSelectAll = (event) => {
        if (event.target.checked) {
            loadWaterData(waterInputsKeys)
        } else {
            loadWaterData([])
        }
    }

    const isAllSelected = () => {
        for (let waterData in waterInputsKeys){
            if (!waterMeterData[waterInputsKeys[waterData]]){
                return false;
            }
        }
        return true;
    }

    let separator = <div className="d-flex align-items-center">
        <div className="" style={{"width": "1px", "height": "20px", top: "2px", backgroundColor: "#DCE0E8"}}></div>
    </div>

    return (
        <div className="d-flex flex-column w-100 h-100">
            <div className="position-absolute text-grey45 accumulatedAbsoluteMenu" style={{
                zIndex: "1",
                border: "1px solid #DCE0E8",
                borderRadius: "5px",
                backgroundColor: "#FFFFFF",
                marginLeft: maxValYAxis >= 10000 ? "88px" : maxValYAxis >= 1000 ? "86px" : maxValYAxis >= 100 ? "82px" : "78px",
                marginTop: "50px", // 15
                fontSize: "14px",
                padding: "0px 16px 4px 16px",
                boxSizing: "border-box",
                boxShadow: "0px 2px 2px rgba(146, 158, 181, 0.1), 0px 0px 6px rgba(145, 157, 180, 0.11)"
            }}>
                {waterMeterData['etc'] &&
                <div className="position-relative row" style={{marginTop: "10px", marginRight:1, marginLeft:1}}>
                    <div className='bg-grey71 mr-1' style={{width:16, height:16, borderRadius:3, marginBottom: "2px"}}/>
                    <span></span> &nbsp;{t('etc')}
                </div>}
                {waterMeterData['irrigation_precipitation'] &&
                <div className="position-relative row" style={{marginTop: "10px", marginRight:1, marginLeft:1}}>
                    <div className='bg-japonica mr-1' style={{width:16, height:16, borderRadius:3, marginBottom: "2px"}}/>
                    <span></span> &nbsp;{t('irrigation') + ' + ' + t('precipitation')}
                </div>}
                {waterMeterData['wfr'] &&
                <div className="position-relative row" style={{marginTop: "10px", marginRight:1, marginLeft:1}}>
                    <div className='bg-lilac mr-1' style={{width:16, height:16, borderRadius:3, marginBottom: "2px"}}/>
                    <span></span> &nbsp;{t('irrigation')}
                </div>}
                {waterMeterData['precipitation_h'] &&
                <div className="position-relative row" style={{marginTop: "10px", marginRight:1, marginLeft:1}}>
                    <div className='bg-blue-third mr-1' style={{width:16, height:16, borderRadius:3, marginBottom: "2px"}}/>
                    <span></span> &nbsp;{t('precipitation')}
                </div>}
                {waterMeterData['plan_irr'] &&
                <div className="position-relative row" style={{marginTop: "10px", marginRight:1, marginLeft:1}}>
                    <div className='bg-blue-primary mr-1' style={{width:16, height:16, borderRadius:3, marginBottom: "2px"}}/>
                    <span></span> &nbsp;{t('Planned irrigation')}
                </div>}
            </div>
            <div className="d-flex justify-content-between" style={{justifyContent:"space-between", paddingLeft: "63px"}}>
                <div>
                    <GraphToggle initialValue='accumulated' onToggleClick={(toggle) => props.onToggleClick(toggle)}
                                 icon={props.icon}/>
                </div>

                <div>
                    <div className="d-flex d-inline align-items-center mr-3">
                        <div className={"d-flex text-size-16 font-weight-bold align-items-center " + graphTitleColor}>{t("accumulated_water_input")}</div>
                        {!dataInLoadingState &&
                        <div className="spinner-border text-grey45 " role="status" style={{"width": "15px", "height": "15px"}}></div>}
                    </div>
                </div>

                <div className={"dropdown text-size-14 text-grey71 mr-2 ml-lg-2 mr-lg-3 align-self-center"}>

                    <div className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
                         aria-expanded="false" style={{'cursor': 'pointer'}}>{t('select_water_input')}</div>
                    <div className="dropdown-menu p-1 pb-0 pt-2 dropdown-menu-right" aria-labelledby="dropdownMenuButton"
                            style={{boxShadow: "0px 6px 15px -2px rgba(0, 0, 0, 0.07), 0px 3px 10px -2px rgba(0, 0, 0, 0.06)"}}>

                        <div className='mb-2'>
                            <div className='d-flex align-items-center '>
                                <input className="ml-2" type="checkbox" checked={isAllSelected()}
                                   onChange={(element) => {
                                       onSelectAll(element)
                                   }}/>
                            <span className="text-size-14 text-grey45 font-weight-regular text-truncate pr-2"
                                  style={{"lineHeight": "20px", padding:'4px 18px 4px 0px', marginLeft:8, marginRight:8}}
                            >{t('select_all')}</span>
                            </div>
                            <div className='d-flex' style={{justifyContent:'space-around'}}>
                                <div className='bg-grey91' style={{height:1, width:'90%'}}/>
                            </div>
                        </div>

                        <div className="d-flex mb-3 align-items-center">
                            <input className="ml-2" type="checkbox" checked={waterMeterData['etc'] || false}
                                   onChange={(element) => {
                                       onSelectionChanged(element, 'etc')
                                   }}/>
                            <span className="text-size-14 text-white font-weight-semi-bold text-truncate pl-2 pr-2 bg-grey71"
                                  style={{"borderRadius": "3px", "lineHeight": "20px", padding:'2px 18px 2px 18px', marginLeft:8, marginRight:8}}
                            >{t('etc')}</span>
                        </div>

                        <div className="d-flex mb-3 align-items-center">
                            <input className="ml-2" type="checkbox" checked={waterMeterData['irrigation_precipitation'] || false}
                                   onChange={(element) => {
                                       onSelectionChanged(element, 'irrigation_precipitation')
                                   }}/>
                            <span className="text-size-14 text-white font-weight-semi-bold text-truncate pl-2 pr-2 bg-japonica"
                                  style={{"borderRadius": "3px", "lineHeight": "20px", padding:'2px 18px 2px 18px', marginLeft:8, marginRight:8}}
                            >{t('irrigation') + ' + ' + t('precipitation')}</span>
                        </div>

                        <div className="d-flex mb-3 align-items-center">
                            <input className="ml-2" type="checkbox" checked={waterMeterData['wfr'] || false}
                                   onChange={(element) => {
                                       onSelectionChanged(element, 'wfr')
                                   }}/>
                            <span className="text-size-14 text-white font-weight-semi-bold text-truncate pl-2 pr-2 bg-lilac"
                                  style={{"borderRadius": "3px", "lineHeight": "20px", padding:'2px 18px 2px 18px', marginLeft:8, marginRight:8}}
                            >{t('irrigation')}</span>
                        </div>

                        <div className="d-flex mb-3 align-items-center">
                            <input className="ml-2" type="checkbox" checked={waterMeterData['precipitation_h'] || false}
                                   onChange={(element) => {
                                       onSelectionChanged(element, 'precipitation_h')
                                   }}/>
                            <span className="text-size-14 text-white font-weight-semi-bold text-truncate pl-2 pr-2 bg-blue-third"
                                  style={{"borderRadius": "3px", "lineHeight": "20px", padding:'2px 18px 2px 18px', marginLeft:8, marginRight:8}}
                            >{t('precipitation')}</span>
                        </div>

                        {shouldDisplayPlanner && <div className="d-flex mb-3 align-items-center">
                            <input className="ml-2" type="checkbox" checked={waterMeterData['plan_irr'] || false}
                                   onChange={(element) => {
                                       setWaterMeterData([]);
                                       onSelectionChanged(element, 'plan_irr')
                                   }}/>
                            <span className="text-size-14 text-white font-weight-semi-bold text-truncate pl-2 pr-2 bg-blue-primary"
                                  style={{"borderRadius": "3px", "lineHeight": "20px", padding:'2px 18px 2px 18px', marginLeft:8, marginRight:8}}
                            >{t('Planned irrigation')}</span>
                        </div>}

                    </div>
                </div>
            </div>
            <div className="flex-grow-1">
                <div className="d-flex h-100">
                    <div className="flex-fill" style={{'width': '40%'}}>
                        <SensorGraph onRef={ref => (setSensor_graph(ref))} onGraphUpdate={this.onGraphUpdate}
                                     hideNavigationButtons={true} units={userUnits} isLoading={false} shouldDrawTodayMark={true}
                                     shouldDrawBottom={false} shouldDrawInputs={false} shouldDrawResetButton={true} toolTipMode={"index"} distributionMode={"linear"} shouldDisplayZoom={false} shouldSpaceData={false} shouldDiplayUTC={false} plannerValue={waterMeterData["plan_irr"] ? true : false} shouldScaleGraph={false}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = function (state) {
    return {
        selectedField: state.distribution_data.selected_entities.field,
        selectedFarmingUnit: state.distribution_data.selected_entities.farming_unit,
        selectedDistribution: state.distribution_data.selected_entities.distribution,
        loadHistoryStatus: state.distribution_data.field_load_history,
        userData: state.authentication_data.user_data,
        field_state: state.distribution_data.field_state
    }
}
const mapDispatchToProps = {}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(WaterMeterGraphContainer));