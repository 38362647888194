var env = {
  prod: {
    url:'https://062wufy9r4.execute-api.eu-west-1.amazonaws.com/prod/',
  },
  devprod: {
    url:'https://062wufy9r4.execute-api.eu-west-1.amazonaws.com/prod/',
  },
  dev: {
    url:'https://062wufy9r4.execute-api.eu-west-1.amazonaws.com/dev/',
  },
  stg: {
    url:'https://062wufy9r4.execute-api.eu-west-1.amazonaws.com/stg/',
  },
  local: {
    url:'http://localhost:3000/',
  },
  stage: 'dev',
  version: '1731313364322',
}

module.exports = env;
