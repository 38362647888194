var React = require('react');
const {connect} = require("react-redux");
const {withTranslation, useTranslation} = require("react-i18next");
const {useState, useEffect, useCallback, useMemo} = require("react");
const $ = require("jquery");
const distributionActions = require('distributionActions');

const BasicInfoComponent = React.memo((props) => {

    const {t} = useTranslation();

    return (
        <div style={{marginBottom:"56px"}}>
            <div className="ml-2 mr-2 mt-3 text-style-titles-20-bold text-grey45">{t("Farming_unit_entity_basic_info")}</div>
            <div className="d-flex flex-row form-row ml-2 mr-2 p-0 mt-3">
                <div className="col-12 col-lg-4 p-0">
                    <div className="form-group mt-3"
                         style={{marginRight: "8px"}}>
                        <label className="text-grey45 text-style-titles-14-semi-bold"
                               htmlFor="distributionName">{t('management_farming_unit_name')}</label>
                        <input type="text" value={props.currentFarmingUnit.name} className="form-control"
                               id="farming_unit_name_input" aria-describedby="emailHelp" onChange={(e) => {
                            props.onChangeFarmingUnitProperties("name", e.target.value);
                        }}
                               placeholder={t('management_farming_unit_name')} required/>
                    </div>
                </div>
            </div>
            <div className={"d-flex flex-row form-row ml-2 mr-2 p-0"}>
                <div className="col-12 col-lg-4 p-0">
                    <div className="form-group mt-3" style={{marginRight: "8px"}}>
                        <label className="text-grey45 text-style-titles-14-semi-bold"
                               htmlFor="distributionName">{t('monday_id')}</label>
                        <input type="text" value={props.currentFarmingUnit?.mondayID} className="form-control"
                               id="farming_unit_name_input" aria-describedby="emailHelp" onChange={(e) => {
                            props.onChangeFarmingUnitProperties("mondayID", e.target.value);
                        }} placeholder={t('monday_id')} required/>
                    </div>
                </div>
                <div className="col-12 col-lg-4 p-0">
                    <div className="form-group mt-3" style={{marginRight: "8px"}}>
                        <label className="text-grey45 text-style-titles-14-semi-bold"
                               htmlFor="distributionName">{t('country')}</label>
                        <select
                            value={props.currentFarmingUnit?.country || Object.keys(props.countryRegionDictionary)[0]}
                            className="form-control white-selector" id="farmingUnitCountryInput"
                            aria-describedby="emailHelp"
                            onChange={(e) => {
                                props.onChangeFarmingUnitProperties("country", e.target.value);
                            }}
                            required>
                            {Object.keys(props.countryRegionDictionary).map((key, index) => {
                                return <option key={index} value={index === 0 ? "" : key}>{key}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className="col-12 col-lg-4 p-0">
                    <div className="form-group mt-3" style={{marginRight: "8px"}}>
                        <label className="text-grey45 text-style-titles-14-semi-bold"
                               htmlFor="distributionName">{t("region")}</label>
                        <select value={props.currentFarmingUnit?.region}
                                disabled={props.currentFarmingUnit?.country === "" || props.currentFarmingUnit?.country === undefined}
                                className={"form-control " + props.currentFarmingUnit?.country === "" || props.currentFarmingUnit?.country === undefined ? "disable-selector" : "white-selector"}
                                id="farmingUnitRegionInput"
                                aria-describedby="emailHelp"
                                onChange={(e) => {
                                    props.onChangeFarmingUnitProperties("region", e.target.value);
                                }}
                                required>
                            {props.currentFarmingUnit?.country !== undefined && props.currentFarmingUnit.region !== undefined ? props.countryRegionDictionary[props.currentFarmingUnit?.country]?.map((key, index) => {
                                return <option key={index} value={key}>{key}</option>
                            }) : ((props.currentFarmingUnit.region === "" || props.currentFarmingUnit.region === undefined) && props.currentFarmingUnit.country !== undefined) ? ["Please select region"].concat(props.countryRegionDictionary[props.currentFarmingUnit?.country]).map((key, index) => {
                                if (index === 0) {
                                    return <option key={index} value={""}>{key}</option>
                                } else {
                                    return <option key={index} value={key}>{key}</option>
                                }
                            }) : <option key={"region"} value={""}>{"Please select country first"}</option>}
                        </select>
                    </div>
                </div>
            </div>
        </div>
    )
});

module.exports = connect()(withTranslation()(BasicInfoComponent));