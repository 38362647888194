import {login} from "../redux/actions/authenticateActions";

var moment = require('moment');

export class PhaseRecord {
    constructor(phase_json, phase_type) {

        if (phase_type === "byGrowthPhaseGDD" && "duration_gdd" in phase_json) {
            this.duration = phase_json["duration_gdd"]
        }

        else if (phase_type === "byGrowthPhase" && "duration_days" in phase_json) {
            this.duration = phase_json["duration_days"]
        }

        else {
            this.duration = phase_json["duration"]
        }

        if ((phase_type === "byGrowthPhaseGDD" || phase_type === "byGrowthPhase" ) && "stress" in phase_json) {
            this["stress"] = {};
            this["stress"].is_stress_active = phase_json["stress"]["is_stress_active"];
            this["stress"].factor = phase_json["stress"]["factor"];

        }
        this.is_dev = phase_json["is_dev"];
        if (this.is_dev) {
            this.name = "Development";
        } else {
            this.name = phase_json["name"];
            this.kc = phase_json["kc"];
        }
    }
}

export class CropData {
    constructor(crop_data_json) {

        this.crop_type = "";
        this.crop_variety = "general";
        this.crop_feature = "general";

        this.harvest_date = "";
        this.sow_date = "";
        this.irrigation_start_date = "";
        this.kc_table = {
            type: "byMonth", data:
                {
                    "byMonth": {
                        'jan': '', 'feb': '', 'mar': '', 'apr': '',
                        'may': '', 'jun': '', 'jul': '', 'aug': '',
                        'sep': '', 'oct': '', 'nov': '', 'dec': ''
                    },
                    "byGrowthPhase": [],
                    "byGrowthPhaseGDD": []
                }
        };

        if (crop_data_json != undefined) {
            this.crop_type = crop_data_json.crop_type.toLowerCase();
            this.crop_variety = crop_data_json.crop_variety.length ? crop_data_json.crop_variety : "general";
            this.crop_feature = crop_data_json.crop_feature.length ? crop_data_json.crop_feature : "general";

            this.sow_date = crop_data_json.sow_date;
            this.irrigation_start_date =  ("irrigation_start_date" in crop_data_json) ? crop_data_json.irrigation_start_date : "";
            this.harvest_date = ("harvest_date" in crop_data_json) ? crop_data_json.harvest_date : "";

            if ("type" in crop_data_json.kc_table) {
                if (crop_data_json.kc_table.type == "byMonth") {
                    this.kc_table.type = "byMonth";
                    this.kc_table.data["byMonth"] =
                        {
                            'jan': crop_data_json.kc_table.data.jan,
                            'feb': crop_data_json.kc_table.data.feb,
                            'mar': crop_data_json.kc_table.data.mar,
                            'apr': crop_data_json.kc_table.data.apr,
                            'may': crop_data_json.kc_table.data.may,
                            'jun': crop_data_json.kc_table.data.jun,
                            'jul': crop_data_json.kc_table.data.jul,
                            'aug': crop_data_json.kc_table.data.aug,
                            'sep': crop_data_json.kc_table.data.sep,
                            'oct': crop_data_json.kc_table.data.oct,
                            'nov': crop_data_json.kc_table.data.nov,
                            'dec': crop_data_json.kc_table.data.dec
                        };
                } else if ((crop_data_json.kc_table.type == "byGrowthPhase") || (crop_data_json.kc_table.type == "byGrowthPhaseGDD")){
                    let all_phases = [];

                    for (let current_phase_index in crop_data_json.kc_table.data) {
                        const current_phase_json = crop_data_json.kc_table.data[current_phase_index];
                        all_phases.push(new PhaseRecord(current_phase_json, crop_data_json.kc_table.type));
                    }
                    this.kc_table.type = crop_data_json.kc_table.type;
                    this.kc_table.data[crop_data_json.kc_table.type] = all_phases;
                }
            }
        }
    }
}

export class GeoData {
    constructor(geo_data_json) {

        if (geo_data_json === undefined) {
            this.altitude = "";
            this.time_zone = "";
            this.coordinates = {
                longitude: "",
                latitude: ""
            };
            this.areas = undefined;
            this.area_size = undefined;
        } else {

            this.altitude = geo_data_json.altitude;
            this.time_zone = geo_data_json.time_zone;
            this.areas = geo_data_json.areas;
            this.area_size = geo_data_json.area_size;

            this.agriapi_polygon_ids = geo_data_json.agriapi_polygon_ids ? geo_data_json.agriapi_polygon_ids : "";

            if ('meteorological_stations' in geo_data_json) {
                this.meteorological_stations = geo_data_json.meteorological_stations;
            } else {
                this.meteorological_stations = [];
            }
        }
    }
}

export const SENSOR_STATES = {
    INIT: 'init',
    LOADING: 'loading',
    COMPLETE: 'complete',
}

export class Sensor {
    constructor(sensor_json, id) {

        this.id = id;

        // Attaching the port to the transmitter ID, this would be the display name in the clients.
        // For instance: mt_6666b, while b is the port and mt_6666 is the transmitter ID
        let transmitter_port = ('port' in sensor_json) ? sensor_json['port'] : '';
        this.transmitter_id = ('transmitter_id' in sensor_json) ? sensor_json['transmitter_id'] + transmitter_port: id;

        this.settings = sensor_json.settings ? sensor_json.settings : {};
        this.name = sensor_json.name ? sensor_json.name : "";
        this.sample_interval = sensor_json.sample_interval ? sensor_json.sample_interval : "";
        this.transmission_interval = sensor_json.transmission_interval ? sensor_json.transmission_interval : "";

        this.displayName = sensor_json.displayName ? sensor_json.displayName : "";

        this.data = sensor_json.data ? sensor_json.data : {};
        this.lastUpdate = sensor_json.lastUpdate ? sensor_json.lastUpdate : moment().unix();
        this.state = sensor_json.state ? sensor_json.state : SENSOR_STATES.INIT;
        this.color = sensor_json.color ? sensor_json.color : "#FFFFFF";

        this.coordinates = sensor_json.coordinates ? sensor_json.coordinates : {latitude: '', longitude: ''};
        this.ignore_in_calculations = sensor_json.settings.ignore_in_calculations ? sensor_json.settings && sensor_json.settings.ignore_in_calculations : false;
        this.paused = sensor_json.settings.paused ? sensor_json.settings && sensor_json.settings.paused : false;
    }
}

// Water potential sensors
export class WPSensor extends Sensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.type = "wp";
    }
}

// Water Flow Rate sensors
export class WFRSensor extends Sensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.type = "wfr";
        this.m3_per_pulse = sensor_json['m3_per_pulse'];
    }
}

// N-drip Pulse sensors
export class PulseSensor extends Sensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.type = "pulse";
        this.m3_per_pulse = sensor_json['m3_per_pulse'];
    }
}

// N-drip Piezometer sensors
export class NDripPiezometer extends Sensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.type = "piezometer";
        this.manufacturer = sensor_json['manufacturer'];
        this.should_consider_as_wfr = sensor_json['should_consider_as_wfr'];
        this.offset = sensor_json['offset'];
    }
}

export class WFRSensorTalgil extends WFRSensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.manufacturer = sensor_json['manufacturer'];
        this.programs = sensor_json['programs'];
        this.valves = sensor_json['valves'];
    }
}

export class WFRSensorGalcon extends WFRSensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.manufacturer = sensor_json['manufacturer'];
        this.programs = sensor_json['programs'];
        this.valves = sensor_json['valves'];
    }
}

export class WFRSensorMotorola extends WFRSensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.manufacturer = sensor_json['manufacturer'];
        this.programs = sensor_json['programs'];
        this.valves = sensor_json['valves'];
    }
}

export class WFRSensorAquastride extends WFRSensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.manufacturer = sensor_json['manufacturer'];
        this.programs = sensor_json['programs'];
        this.valves = sensor_json['valves'];
    }
}

export class WFRSensorNDrip extends WFRSensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.manufacturer = sensor_json['manufacturer'];
        this.programs = sensor_json['programs'];
        this.valves = sensor_json['valves'];
        this.sub_type = sensor_json['sub_type'];
    }
}

export class WFRSensorNDripPulse extends PulseSensor {
    constructor(sensor_json, id) {
        super(sensor_json, id);
        this.manufacturer = sensor_json['manufacturer'];
        this.valves = sensor_json['valves'];
    }
}

export class ContractData {
    constructor(contract_json) {

        if (contract_json === undefined) {
            this.expiration_date = moment().add(1, "years").unix();
            this.installation_date = moment().unix();
        } else {
            this.expiration_date = contract_json.expiration_date;
            this.installation_date = contract_json.installation_date;
        }

    }
}

export class Field {
    constructor(field_json, id) {

        const historicalDataTemplate = {
            etc: {units: "unknown", values: {}},
            et0: {units: "unknown", values: {}},
            ir: {units: "unknown", values: {}},
            ir_date: {units: "unknown", values: {}},
            temperature: {units: "unknown", values: {}},
            kc: {units: "unknown", values: {}},
            stress: {units: "unknown", values: {}},
            adjusted_stress: {units: "unknown", values: {}},
            selected_sensor: {units: "unknown", values: {}},
            "n_in_plant": {units: "unknown", values: {}},
            "n_in_soil": {units: "unknown", values: {}},
            "n_atmo_loss": {units: "unknown", values: {}},
            "n_leached": {units: "unknown", values: {}},
            "c_leaf": {units: "unknown", values: {}},
            "plant_height": {units: "unknown", values: {}},
            "petiole": {units: "unknown", values: {}},
        };

        if (field_json === undefined) {
            this.id = "";
            this.name = "";
            this.soil_type = "clay";
            this.crop_data = new CropData();
            this.geo_data = new GeoData();
            this.cluster = {id: ""};
            this.farming_type = "open_field";

            this.contract_data = new ContractData();
            this.historical_data = historicalDataTemplate;

            this.irrigation_system = {irrigation_type: "", irrigation_rate: 0, irrigation_hin: 0, tank_type:"",
                // measured_irrigation_rate:0,
                time_frames: [], fertigation_concentrations:[]};

            this.sensors = {};
            this.settings = {
                is_coupmodel_active: false,
                coup_settings: {should_automate_irrigations: false},
                field_state: "active",
                is_rolling_fit_active: true,
                is_measured_rate_active: true
            };
            this.initial_concentrations = {
                N_Concenration_Organic_Matter: '',
                NO3_Concentration: '',
                NH4_Concentration: ''
            };

        } else {
            this.id = id;
            this.name = field_json.name;
            this.soil_type = field_json.soil_type;
            this.cluster = field_json.cluster ? field_json.cluster : {id: ""};
            this.crop_data = new CropData(field_json.crop_data);
            this.geo_data = new GeoData(field_json.geo_data);
            this.contract_data = new ContractData(field_json.contract_data);
            this.farming_type = field_json.farming_type ? field_json.farming_type : "open_field";

            this.initial_concentrations = 'soil_data' in field_json ? field_json.soil_data.initial_concentrations : {
                N_Concenration_Organic_Matter: '',
                NO3_Concentration: '',
                NH4_Concentration: ''
            }

            this.historical_data = historicalDataTemplate;

            this.irrigation_system = field_json.irrigation_system ? {
                fertigation_concentrations: field_json.irrigation_system.fertigation_concentrations ? field_json.irrigation_system.fertigation_concentrations : [],
                irrigation_type: field_json.irrigation_system.irrigation_type ? field_json.irrigation_system.irrigation_type : "",
                tank_type: field_json.irrigation_system.tank_type ? field_json.irrigation_system.tank_type : "",
                // measured_irrigation_rate: field_json.irrigation_system.measured_irrigation_rate ? field_json.irrigation_system.measured_irrigation_rate : 0,
                irrigation_rate: field_json.irrigation_system.irrigation_rate ? field_json.irrigation_system.irrigation_rate : 0,
                irrigation_hin: field_json.irrigation_system.irrigation_hin ? field_json.irrigation_system.irrigation_hin : 0,
                time_frames: field_json.irrigation_system.time_frames ? field_json.irrigation_system.time_frames : [],
            } : {irrigation_type: "", irrigation_rate: 0,irrigation_hin: 0, time_frames: [], fertigation_concentrations:[]};

            this.settings = {
                is_coupmodel_active: (field_json.settings.is_coupmodel_active ? field_json.settings.is_coupmodel_active : false),
                coup_settings: {should_automate_irrigations: (field_json.settings.coup_settings && field_json.settings.coup_settings.should_automate_irrigations ? field_json.settings.coup_settings.should_automate_irrigations : false)},
                is_rolling_fit_active: field_json.settings.is_rolling_fit_active ? field_json.settings.is_rolling_fit_active : false,
                is_measured_rate_active: field_json.settings.hasOwnProperty("is_measured_rate_active") ? field_json.settings.is_measured_rate_active : true,
                field_state: field_json.settings.field_state ? field_json.settings.field_state : 'deleted'
            };
            this.sensors = {};
            for (var i in field_json.sensors) {

                let sensorJson = field_json.sensors[i];
                let sensor_object = undefined;

                if (('type' in sensorJson) && (sensorJson['type'].toLowerCase() == 'wfr')) {
                    if (('manufacturer' in sensorJson) && (sensorJson['manufacturer'].toLowerCase() == 'tal-gil')) {
                        sensor_object = new WFRSensorTalgil(field_json.sensors[i], i);
                    } else if (('manufacturer' in sensorJson) && (sensorJson['manufacturer'].toLowerCase() == 'motorola')) {

                        sensor_object = new WFRSensorMotorola(field_json.sensors[i], i);
                    } else if (('manufacturer' in sensorJson) && (sensorJson['manufacturer'].toLowerCase() == 'galcon')) {

                        sensor_object = new WFRSensorGalcon(field_json.sensors[i], i);
                    } else if (('manufacturer' in sensorJson) && (sensorJson['manufacturer'].toLowerCase() == 'aquastride')) {

                        sensor_object = new WFRSensorAquastride(field_json.sensors[i], i);
                    } else if (('manufacturer' in sensorJson) && (sensorJson['manufacturer'].toLowerCase() == 'ndrip')) {

                        sensor_object = new WFRSensorNDrip(field_json.sensors[i], i);
                    } else {
                        sensor_object = new WFRSensor(field_json.sensors[i], i);
                    }
                } else if (('type' in sensorJson) && (sensorJson['type'].toLowerCase() == 'pulse')) {
                    // add manufacturer checks for new Pulse sensors in future
                    sensor_object = new WFRSensorNDripPulse(field_json.sensors[i], i);
                } else if (('type' in sensorJson) && (sensorJson['type'].toLowerCase() == 'piezometer')) {
                    // add manufacturer checks for new Piezometer sensors in future
                    sensor_object = new NDripPiezometer(field_json.sensors[i], i);
                } else {
                    sensor_object = new WPSensor(field_json.sensors[i], i);
                }

                this.sensors[sensor_object.id] = sensor_object;
            }
        }
    }
}

export class FarmingUnit {
    constructor(farming_unit_json, id) {

        this.id = id;
        this.name = farming_unit_json.name;
        this.salesforceID = farming_unit_json.salesforce_id;
        this.role = farming_unit_json.role;
        this.country = farming_unit_json.country;
        this.region = farming_unit_json.region;
        this.historical_data = {}
        let sensorDisplay = farming_unit_json.settings.sensor_display ? farming_unit_json.settings.sensor_display : "all";
        let isPlannerActive = farming_unit_json.settings.is_planner_active ? farming_unit_json.settings.is_planner_active : false;

        this.wt = farming_unit_json.wt ? farming_unit_json.wt : {};
        this.settings = {
            is_active: farming_unit_json.settings.is_active,
            sensor_display: sensorDisplay,
            is_planner_active: isPlannerActive
        };
        this.fields = {};
        this.sensors = {};

        for (var i in farming_unit_json.fields) {
            var field_object = new Field(farming_unit_json.fields[i], i);
            this.fields[field_object.id] = field_object;
        }
    }
}

export class Distribution {

    constructor(distribution_json) {

        this.id = distribution_json["id"];
        this.name = distribution_json["name"];
        this.region = distribution_json["region"];
        this.role = distribution_json["role"];
        this.farming_units = {};

        for (var i in distribution_json.farming_units) {
            var farming_unit_object = new FarmingUnit(distribution_json.farming_units[i], i);
            this.farming_units[farming_unit_object.id] = farming_unit_object;
        }
    }
}

export class Distributions {

    constructor(distributions_json) {

        this.distributions = {};

        for (var i in distributions_json) {
            var distribution_object = new Distribution(distributions_json[i]);
            this.distributions[distribution_object.id] = distribution_object;
        }
    }
}

export class SoilData {

    constructor(soil_data_json) {

        this.soil_data = {};

        for (var i in soil_data_json) {
            this.soil_data[soil_data_json[i]['soil_type'].toLowerCase()] = soil_data_json[i];
        }
    }
}

export class FertilizerData {

    constructor(fertilizer_data_json) {

        this.fertilizer_data = {};

        for (var i in fertilizer_data_json) {
            this.fertilizer_data[fertilizer_data_json[i]['fertilizer_type'].toLowerCase()] = fertilizer_data_json[i];
        }
    }
}

export class CropRecords {
    constructor(cropDataJson) {
        this.crop_data = {};
        cropDataJson.sort((a,b) => {
            if (a.crop_type < b.crop_type) {
                return -1
            }
            if (a.crop_type > b.crop_type) {
                return 1
            }
            return 0
        })
        cropDataJson.forEach((cropData) => {
            const crop_type = cropData.crop_type;
            const type = cropData.type;
            const feature = cropData.feature;
            const variety = cropData.variety;
            const phenological_phases = cropData.phenological_phases;
            const gdd = cropData.gdd || undefined;
            const coup_parameters = cropData.coup_parameters || undefined;
            const yieldData = cropData.yield;

            if (!this.crop_data[type]) {
                this.crop_data[type] = {};
            }

                if (!this.crop_data[type][variety]) {
                    this.crop_data[type][variety] = {}
                }
                if (variety === "general" && feature !== 'general') {
                    for (let cropDatumKey in this.crop_data[type]) {
                        if (!(feature in this.crop_data[type][cropDatumKey])) {
                            this.crop_data[type][cropDatumKey][feature] = {
                                crop_type,
                                phenological_phases,
                                coup_parameters,
                                gdd,
                                yield: yieldData
                            };
                        }

                    }
                }

                this.crop_data[type][variety][feature] = {crop_type,phenological_phases, coup_parameters, gdd, yield:yieldData};
        });
    }
}


export class Role {

    constructor(role_json) {

        this.id = role_json.id;
        this.distributions = {};

        for (var i in role_json.distributions) {
            this.distributions[i] = role_json.distributions[i];
        }
    }
}

export class RoleList {

    constructor(role_list) {

        this.roles = {};

        for (var i in role_list) {
            this.roles[role_list[i].id] = new Role(role_list[i]);
        }
    }
}


export class LocalError {

    constructor(message) {
        this.response = {data: {error: {message: message}}};
    }
}

export class InsightsField {
    constructor(insightsField_json) {

        if (insightsField_json === undefined) {
            this.field_id = "";
            this.field_name = "";
            this.field_size = 0;
            this.crop_type = "";
            this.days_to_harvest = 0;
            this.total_precipitation = 0;
            this.planned_fert = 0;
            this.used_fert = 0;
            this.planned_irr = 0;
            this.used_irr = 0;
            this.irr_deficit = 0;
            this.fert_deficit = 0;
            this.nitrogen_leached = 0;
            this.nitrogen_efficiency = 0;

        } else {
            this.field_id = insightsField_json.id;
            this.field_name = insightsField_json.name;
            this.field_size = insightsField_json.field_size;
            this.crop_type = insightsField_json.crop_type;
            this.days_to_harvest = insightsField_json.days_to_harvest;
            this.total_precipitation = insightsField_json.precipitation_h;
            this.planned_fert = insightsField_json.plan_fert;
            this.used_fert = insightsField_json.used_fert;
            this.planned_irr = insightsField_json.plan_irr;
            this.used_irr = insightsField_json.used_irr;
            this.irr_deficit = insightsField_json.irr_deficit;
            this.fert_deficit = insightsField_json.fert_deficit;
            this.nitrogen_leached = insightsField_json.n_leached;
            this.nitrogen_efficiency = insightsField_json.nitrogen_efficiency;
        }
    }
}


export class InsightsFarmingUnit {
    constructor(insightsFarmingUnit_json) {

        if (insightsFarmingUnit_json === undefined) {
            this.farming_unit_name = "";
            this.farming_unit_id = "";
            this.total_used_irrigation = 0;
            this.total_used_fert = 0;
            this.total_precipitation = 0;
            this.total_planned_irrigation = 0;
            this.total_planned_fert = 0;
            this.irr_deficit = 0;
            this.fert_deficit = 0;
            this.nitrogen_efficiency = 0;
            this.fields_details = [];

        } else {
            this.farming_unit_name = insightsFarmingUnit_json.farming_unit_name;
            this.farming_unit_id = insightsFarmingUnit_json.farming_unit_id;
            this.total_used_irrigation = insightsFarmingUnit_json.total_used_irrigation;
            this.total_used_fert = insightsFarmingUnit_json.total_used_fert;
            this.total_precipitation = insightsFarmingUnit_json.total_precipitation;
            this.total_planned_irrigation = insightsFarmingUnit_json.total_planned_irrigation;
            this.total_planned_fert = insightsFarmingUnit_json.total_planned_fert;
            this.irr_deficit = insightsFarmingUnit_json.irr_deficit;
            this.fert_deficit = insightsFarmingUnit_json.fert_deficit;
            this.nitrogen_efficiency = insightsFarmingUnit_json.nitrogen_efficiency;
            let fieldsArray = Object.values(insightsFarmingUnit_json.fields_details);
            this.fields_details = [];
            for (var i in fieldsArray) {
                var field_object = new InsightsField(fieldsArray[i]);
                this.fields_details[field_object.field_id] = field_object;
            }
        }
    }
}

export class InsightsDistribution {
    constructor(insightsDistribution_json) {

        if (insightsDistribution_json === undefined) {
            this.distribution_id = "";
            this.distribution_name = "";
            this.distribution_field_count = 0;
            this.distribution_total_area = 0;
            this.distribution_total_water_usage = 0;
            this.distribution_crop_types = 0;
            this.distribution_total_fertilizers_usage = 0;
            this.distribution_total_nitrogen_leached = 0;

            this.distribution_farming_units = [];

        } else {
            this.distribution_name = insightsDistribution_json.distributionName;
            this.distribution_id = insightsDistribution_json.distributionId;
            this.distribution_field_count = insightsDistribution_json.numOfFields;
            this.distribution_total_area = insightsDistribution_json.totalArea;
            this.distribution_total_water_usage = insightsDistribution_json.totalWaterUsage;
            this.distribution_crop_types = insightsDistribution_json.cropTypes;
            this.distribution_total_fertilizers_usage = insightsDistribution_json.totalFertilizersUsage;
            this.distribution_total_nitrogen_leached = insightsDistribution_json.totalNitrogenLeached;
            this.distribution_farming_units = [];

            for (var i in insightsDistribution_json.farmingUnitsArray) {
                var farming_unit_object = new InsightsFarmingUnit(insightsDistribution_json.farmingUnitsArray[i]);
                this.distribution_farming_units[farming_unit_object.farming_unit_id] = farming_unit_object;
            }
        }
    }
}

export class Notification {
    constructor(notification_json) {

        this.distribution_id = notification_json.distribution_id;
        this.field_id = notification_json.field_id;
        this.field_name = notification_json.field_name;
        this.farming_unit_name = notification_json.farming_unit_name;
        this.farming_unit_id = notification_json.farming_unit_id;
        this.date = notification_json.date;
        this.notification_text = notification_json.notification_text;
        this.sensor_name = notification_json.hasOwnProperty("sensor_name") ? notification_json.sensor_name : "";
    }
}