import React, {forwardRef, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactDatetime from "react-datetime";
import {useTranslation} from "react-i18next";
import $ from "jquery";
var {NDCheckBox} = require("@stories/NDCheckBox");
var {compare} = require('Utils');
var {upper, calculateDischargeRate, calculateDischargeDeviation, DISCHARGE_ALERT_THRESHOLD} = require('helpers');
const {SelectMenu} = require('SelectMenu');
var {nitrogenInFert, fertilizerList, fertilizerNameToDisplay, getMatterState} = require('nitrogenManager');
var {unitDisplayName} = require('unitsManager');
import * as fieldManager from "@managers/fieldManager";
var {get_field_history} = require('distributionActions');
import {waterTreatmentDisplayName, getUnitsForWTRType} from "@managers/planningManager";
import moment from "moment";
require('figmaCSS');

export const mapType = {
    'irrigation': 'plan_irr',
    'fertilization': 'plan_fert',
    'note': 'plan_note',
};


export const ModalContainer = (props) => {
    const  defaultStyle = {
        height: "100%",
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        margin:'auto'
    }
    let style = props.style || defaultStyle;

    return(
    <div className="modal fade" id={props.id} tabIndex="-1" role="dialog"
           aria-labelledby={props.id+"Label"} aria-hidden="true" style={{overflow:'hidden'}} onClick={props.handleClose}>
        <div className="modal-dialog" style={{...style}} role="document" >
          {props.children}
        </div>
      </div>
  )
}

export const SuccessMessage = ({t, eventType, status=''}) => {
    let massage = '';
    let image = '';
    if (status === 'success') {
        image = require('images/icons/success_icon_bold.svg');
    } else {
        image = require('images/icons/fail_icon_bold.svg');
    }
    switch (eventType) {

        case 'irrigation':
            if (status == "success") {
                massage = t('irrigation_reported_successfully');
            }
            else  {
                massage = t('report_unsuccessfully');
            }
            break;

        case 'fertilization':
             if (status == "success") {
                massage = t('fertilization_reported_successfully');
            }
            else  {
                massage = t('report_unsuccessfully');
            }
            break;

        case 'rain':
              if (status == "success") {
                massage = t('rain_reported_successfully');
            }
            else  {
                massage = t('report_unsuccessfully');
            }
            break;

        case 'discharge':
            if (status == "success") {
                massage = t('reported_successfully');
            } else {
                massage = t('report_unsuccessfully');
            }
            break;
        case 'water treatment':
            if (status == "success") {
                massage = t('wtr_reported_successfully');
            } else {
                massage = t('report_unsuccessfully');
            }
            break;

        case 'delete':
              if (status == "success") {
               massage = t('event_delete_successfully');
            }
            else  {
                massage = t('event_delete_unsuccessfully');
            }
    }

    return (
      <div className="modal-content" style={{width:400, alignItems:'center', display:"flex", flexDirection:"row", borderRadius:"10px", border:"1px solid #DCE0E8", paddingRight:"14px"}}>
          <img style={{width:40, height:40, marginTop:"24px", marginLeft:"24px", marginBottom:"24px", marginRight:"16px"}} src={image}/>
            <div className='text-grey45 text-size-16 font-weight-semi-bold align-self-center'
                 style={{textAlign: 'center'}}>{massage}</div>
        </div>
    )
}

export const DeleteDialogModalContent = ({t, setDisplayActionModal, setActionModalStatus, item}) => {

    const [loading, setLoading] = useState(false);
    const selectedDistribution = useSelector((state) => state.distribution_data.selected_entities.distribution);
    const selectedFarmingUnit = useSelector((state) => state.distribution_data.selected_entities.farming_unit);
    const selectedField = useSelector((state) => state.distribution_data.selected_entities.field);
    const dispatch = useDispatch();

    const onDeleteAction = (status) => {
        setLoading(true);
        setActionModalStatus(status);
        dispatch(get_field_history(selectedDistribution.id, selectedFarmingUnit.id, selectedField.id));
        setTimeout(function () {
            setDisplayActionModal(true);
        }, 1000)
        setTimeout(function () {
            $("#deleteModal").modal('hide');
            setLoading(false);
        }, 3000);
        setTimeout(function () {
            setDisplayActionModal(false);
        }, 3500);
    }

    const deleteReportedEvent = () => {
        let disableEventRecovery = ["piezometer", "wfr_ndrip", "pulse"].includes(item?.source);

        if (item.type === 'discharge') {

            if (item.source === 'manual') {
                fieldManager.deleteDischargeTestEvent(item, selectedField).then(function () {
                    onDeleteAction("success");
                }, function () {
                    onDeleteAction("fail");
                })

            } else {
                fieldManager.deleteWfrDischargeEvent(item, selectedField).then(function () {
                    onDeleteAction("success");
                }, function () {
                    onDeleteAction("fail");
                })
            }

        } else if (item.type === 'irrigation') {
            if (item.source !== 'manual') {
                item.source = item.sourceID;
            }
            fieldManager.deleteIrrigationEvent(item, disableEventRecovery, selectedField).then(function () {
                onDeleteAction("success");
            }, function () {
                onDeleteAction("fail");
            })

        } else if (item.type === "fertilization") {
            fieldManager.deleteFertilizationEvent(item, selectedField).then(function () {
                onDeleteAction("success");
            }, function () {
                onDeleteAction("fail");
            })
        } else if (item.type === "wtr") {
            fieldManager.deleteWtrEvent(item, selectedField).then(function () {
                onDeleteAction("success");
            }, function () {
                onDeleteAction("fail");
            })
        }
        mixpanel.track("Reported Event Deleted", {
            'Event Type': item.type,
            "Delete Source": "Reported List"
        });
    }

    return (
        <div className="modal-content" style={{
            width: 304,
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: "32px",
            borderColor: "#DCE0E8",
            borderRadius: "8px"
        }}>
            <img style={{width: 80, height: 80}} src={require('images/icons/delete_modal_icon.svg')}/>
            <div className='text-grey45 text-size-24 font-weight-bold align-self-center'
                 style={{textAlign: 'center', marginTop: "16px", width: 224}}>{t("delete_item_title")}</div>
            <div className='text-grey45 text-size-16 align-self-center'
                 style={{textAlign: 'center', marginTop: "8px", width: 224}}>{t("delete_item_text")}</div>
            <div style={{
                padding: "32px",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "8px"
            }}>
                <button className="danger-button" style={{height: "40px", width: "100%", borderRadius: "3px"}}
                        onClick={deleteReportedEvent}>{loading ?
                    <span style={{color: "white", marginTop: "4px"}} className="spinner-border spinner-border-sm mb-1"
                          role="status" aria-hidden="true"></span> : t("delete")}</button>
                <button className='ghost-button' style={{height: "40px", width: "100%", borderRadius: "3px"}}
                        onClick={() => {
                            $("#deleteModal").modal('hide')
                        }}>{t("cancel")}</button>
            </div>
        </div>
    )
}

export const ModalContent = forwardRef((props,ref) => {
    const {t, i18n} = useTranslation();
    let {reportedData, setReportedData, measurement, setMeasurement, eventType, selectedField, userData} = props;

    let enableDelete = !!props.onDeleteClick;
    let footerStyle = enableDelete ? 'justify-content-between mr-2 ml-2' : 'justify-content-center';
    let shouldDisplayMeasurementLine = eventType === 'irrigation' && !!selectedField?.irrigation_system?.irrigation_rate;
    let durationUnits = t('hours');
    let amountUnits = 'length';
    amountUnits = amountUnits in userData ? unitDisplayName(userData[amountUnits]) : '';

    const isValidDateToSelect = (current) => {
        if (props?.title?.includes("planner")) {
            return selectedField?.crop_data.irrigation_start_date ? current.isSameOrAfter(selectedField?.crop_data?.irrigation_start_date) : current.isSameOrAfter(selectedField?.crop_data?.sow_date);
        } else {
            let followingDay = moment().add(1, "days").format("YYYY MM DD");
            // if irrigation start date is set, only allow dates between irrigation start date and following day else allow dates between sow date and following day
            if (selectedField?.crop_data.irrigation_start_date) {
                return (current.isSameOrAfter(selectedField?.crop_data?.irrigation_start_date) && current.isSameOrBefore(followingDay));
            } else {
                return (current.isSameOrAfter(selectedField?.crop_data?.sow_date) && current.isSameOrBefore(followingDay));
            }
        }
    }

    function chipMode(type) {
        return measurement == type ? 'selected' : 'notselected';
    }
    return(
        <div className="modal-content ml-1 mr-1 border-grey91" style={{width:428, borderRadius:10, overlay: {zIndex: 1000}}} onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <div className="text-grey45 font-weight-bold text-size-20" id="reportEventModalLabel">{upper(props.title)}</div>
        </div>
        <div className="modal-body ml-2 mr-2">
          <div style={{marginBottom:10}}>
              {props.displayFieldSection &&
              <div>
                  <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{upper(t('field'))}</div>
                  <FieldDropDown fieldId={reportedData.fieldId} changeFieldId={(fieldId) => {
                      setReportedData({...reportedData, fieldId: fieldId})
                  }}/>
              </div>
              }
            <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2'>{upper(t('event'))}</div>
              {props.eventTypesChips}
          </div>

          <div className='mb-2'>
            <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{upper(t('starting_time'))}</div>

              <ReactDatetime isValidDate={isValidDateToSelect} dateFormat="MMM Do YYYY" locale={i18n.language} autoComplete="off"
                             onChange={(time) => {setReportedData({...reportedData, startTime:time})}} value={reportedData.startTime}
                             inputProps={{onKeyDown: (e) => {e.preventDefault()},
                             required: true, id: 'datetimeInput', className: "form-control border text-grey45 font-weight-semi-bold p-1 pl-2 mr-3",
                             style: {borderRadius: 5, width: '240px', height: 40}}}/>
          </div>
            {shouldDisplayMeasurementLine &&
                <div style={{marginBottom:10}}>
                    <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2'>{upper(t('measurement'))}</div>
                    <div className='ml-1 row'>
                        <div className={`chip chip--${chipMode('duration')}`}
                             onClick={() => setMeasurement('duration')}>{t('duration')}</div>
                        <div className={`chip chip--${chipMode('amount')}`}
                             onClick={() => setMeasurement('amount')}>{upper(t('amount'))}</div>
                    </div>
                </div>}

          {eventType === 'irrigation' &&
              <IrrigationSelection t={t} reportedData={reportedData} setReportedData={setReportedData} amountUnits={amountUnits} durationUnits={durationUnits} userData={userData}
                                selectedMeasurement={shouldDisplayMeasurementLine && measurement} irrigationRate={selectedField?.irrigation_system?.irrigation_rate}
                                displayAddWTRCheckbox={props.displayAddWTRCheckbox} enableAddingWTR={props.enableAddingWTR} setEnableAddingWTR={props.setEnableAddingWTR}/>
          }

          {eventType === 'rain' &&
                <RainSelection t={t} data={reportedData} setData={setReportedData}
                               amountUnits={amountUnits} durationUnits={durationUnits}/>
          }

          {eventType === 'fertilization' &&
                <FertilizerTypeSelection t={t} data={reportedData} setData={setReportedData} userData={userData}/>
          }

          {eventType === 'note' &&
                <FootnoteSection t={t} data={reportedData} setData={setReportedData}/>
          }

          {eventType === 'discharge' &&
                <DischargeSection t={t} data={reportedData} setData={setReportedData}/>
          }

          {eventType === 'water treatment' &&
                <WaterTreatmentSection data={reportedData} setData={setReportedData} userData={userData}/>
          }

        </div>
        <div className={"modal-footer " + footerStyle}>
            {enableDelete && <img src={require('images/icons/planner/trash.svg')} className="img-fluid" alt="irrigation ok"
                  onClick={props.onDeleteClick}/>}
            <div className='row'>
                <button className="d-flex justify-content-center align-items-center font-weight-semi-bold text-size-16 ghost-button mr-2"
                     style={{width: 120, height: 40, borderRadius: 3}} data-dismiss="modal">{upper(t('close'))}</button>
                <button disabled={!props.enableAct()} className="d-flex justify-content-center align-items-center font-weight-semi-bold text-size-16 text-white primary-button"
                     style={{width: 120, height: 40, borderRadius: 3}} onClick={props.onActionButtonClick}>{upper(props.actionButtonText)}</button>
            </div>
        </div>
      </div>

    )
})

function FieldDropDown({fieldId, changeFieldId}) {
    const {t} = useTranslation();
    const selectedFarmingUnit = useSelector((state) => state.distribution_data.selected_entities.farming_unit);

    const currentField = selectedFarmingUnit?.fields[fieldId];
    const fieldsAsArray = Object.entries(selectedFarmingUnit?.fields);

    let activeFields = fieldsAsArray.filter(([key, field]) => field.settings.field_state === "active").sort(compare);
    const fieldsList = Object.fromEntries(activeFields);

    const sortedFieldKeys = fieldsList ? Object.keys(fieldsList).sort((a, b) => (fieldsList[a].name > fieldsList[b].name) ? 1 : -1) : []
    let fieldsOption = sortedFieldKeys.map((fieldId) => {
                            return {value: fieldId, title: fieldsList[fieldId].name}})
    return (
        <SelectMenu menuStyle={{height:40, borderWidth: 1,  borderColor: 'grey91', fontSize:16, padding: "8px", backgroundColor:'white', color:'white'}}
                    options={fieldsOption} onChange={(fieldId) => changeFieldId(fieldId)}
                    placeholder={t('please select')} curValue={currentField?.id}
                    sortBox={"sortBox"} selectClassName={" border-grey91 " + (currentField?.name ? "text-grey45" : "text-grey71")}/>
    );
}

function WaterTreatmentSection({data, setData, userData}) {

    const {t} = useTranslation();

    const handleAmountChange = ({target}) => {
        setData({...data, wtrAmount:target.value});
    }

    const handleTypeChange = (wtrType) => {
        setData({...data, wtrType: wtrType})
    }

    let wtrOptions = ['hpo', 'acid', 'cs', 'sanidate'].sort().map((wtrType) => {
                            return {value: wtrType, title: waterTreatmentDisplayName[wtrType]}})

    let amountTitle = upper(t('amount'));
    let wtrUnitType = getUnitsForWTRType(data?.wtrType);
    if (data?.wtrType && wtrUnitType) {
        let wtrUnits = (userData && (wtrUnitType in userData)) ? unitDisplayName(userData[wtrUnitType]) : "";
        amountTitle += " ({0})".format(wtrUnits);
    }

    return (
        <div className='row m-0'>

            <div style={{width:240}}>
                <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{upper(t('type'))}</div>
                <SelectMenu menuStyle={{
                    height: 40,
                    borderWidth: 1,
                    borderColor: 'grey91',
                    fontSize: 16,
                    padding: "8px",
                    backgroundColor: 'white'
                }}
                            options={wtrOptions} onChange={(wtrType) => handleTypeChange(wtrType)}
                            placeholder={t('please select')} curValue={data?.wtrType}
                            sortBox={"sortBox"}
                            selectClassName={" border-grey91 " + (data?.wtrType ? "text-grey45" : "text-grey71")}/>
            </div>

            <div style={{flex: 1, marginLeft:8}}>
                <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{amountTitle}</div>
                <input type="number" min="0" step="0.5" placeholder="0"
                       value={data?.wtrAmount || ''} onChange={handleAmountChange}
                       className="form-control border text-grey45 font-weight-semi-bold p-1 pl-2"
                       style={{borderRadius: 5, height: 40, width: '100%'}}/>
            </div>
        </div>
    );
}

function AddWaterTreatment({data, setData, enableAddingWTR, setEnableAddingWTR, userData}) {
    const {t} = useTranslation();

    return (
        <div>
            <div className='row ml-0 align-items-center'>
            <NDCheckBox onClick={setEnableAddingWTR} mode={enableAddingWTR} size={'small'}/>
                <span className='text-style-short-lables-16-regular' style={{marginLeft:8}}>{t('wtr_checkbox')}</span>
            </div>
            {Boolean(enableAddingWTR) &&
                <WaterTreatmentSection data={data} setData={setData} userData={userData}/>}
        </div>
    );
}

const IrrigationSelection = (props) => {

    return(
        <div>
            <DurationAmountInputs {...props}/>
            {props.displayAddWTRCheckbox &&
                <AddWaterTreatment data={props.reportedData} setData={props.setReportedData} userData={props.userData}
                                   enableAddingWTR={props.enableAddingWTR} setEnableAddingWTR={props.setEnableAddingWTR}/>}
        </div>
    )
}
const RainSelection = ({t, data, setData, amountUnits, durationUnits}) => {
    return <DurationAmountInputs t={t} reportedData={data} setReportedData={setData}
                                 amountUnits={amountUnits} durationUnits={durationUnits}/>
}
const FertilizerTypeSelection = ({t, data, setData, userData}) => {

    const handleAmountChange = ({target}) => {
        setData({...data, fertAmount: target.value, nitroAmount: nitrogenInFert(data.fertType, target.value)});
    }

    const handleTypeChange = (fertType) => {
        setData({...data, fertType: fertType, nitroAmount: nitrogenInFert(fertType, data.fertAmount)})
    }

    let nitroUnits = (userData && ("weight" in userData)) ? unitDisplayName(userData.weight) + "N/" + unitDisplayName(userData.area) : "";
    let fertUnits = '';
    if (!!data.fertType)
        fertUnits = (userData && ("weight" in userData)) ? unitDisplayName(userData.weight) + "/" + unitDisplayName(userData.area) : "";
    if (getMatterState(data.fertType) === 'liquid')
        fertUnits = (userData && ("small_volume" in userData)) ? unitDisplayName(userData.small_volume) + "/" + unitDisplayName(userData.area) : ""

    let amountTitle = upper(t('amount'));
    if (fertUnits)
         amountTitle += " (" + fertUnits + ")"

    let fertilizerOption = fertilizerList().sort().map((fertType) => {
                            return {value: fertType, title: fertilizerNameToDisplay(fertType)}})

    const checkFertType = () => fertilizerOption.some((option) => option.value === data?.fertType)


    return (
        <div>
            <div>
                <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{upper(t('type'))}</div>

                <SelectMenu menuStyle={{height:40, borderWidth: 1,  borderColor: 'grey91', fontSize:16, padding: "8px", backgroundColor:'white'}}
                            options={fertilizerOption} onChange={(fertType) => handleTypeChange(fertType)}
                            placeholder={t('please select')} curValue={checkFertType() ? data?.fertType : fertilizerOption[0].value}
                            sortBox={"sortBox"} selectClassName={" border-grey91 " + (data?.fertType ? "text-grey45" : "text-grey71")}/>

            </div>
            <div className='d-flex mb-3'>
                <div className='modal-input-container'>
                    <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{amountTitle}</div>
                    <input type="number" min="0" step="0.5" placeholder="0" value={1*parseFloat(data.fertAmount)?.toFixed(3) || ''} onChange={handleAmountChange}
                           className="form-control border text-grey45 font-weight-semi-bold p-1 pl-2" style={{borderRadius: 5, height: 40, width:'100%'}}/>
                </div>
                <div style={{width:8, flex:"none"}}/>
                <div className='modal-input-container'>
                    <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{nitroUnits}</div>
                    <input type="number" min="0" step="0.01" placeholder="0" value={1*parseFloat(data.nitroAmount)?.toFixed(3) || ''} readOnly disabled={true}
                           className="form-control border font-weight-semi-bold p-1 pl-2 bg-grey98 text-grey72" style={{borderRadius: 5, height: 40, width:'100%'}}/>
                </div>
            </div>
        </div>
    )
}

const DurationAmountInputs = ({t, reportedData, setReportedData, selectedMeasurement, irrigationRate, amountUnits, durationUnits}) => {

    let disableStyle = ' bg-grey98 text-grey72';
    let considerIrrigationRate = !!selectedMeasurement;
    let durationDisabled = selectedMeasurement && selectedMeasurement !== 'duration';
    let durationStyle = durationDisabled ? disableStyle : '';
    let amountDisabled = selectedMeasurement && selectedMeasurement !== 'amount';
    let amountStyle = amountDisabled ? disableStyle : '';

    const handleDurationChange = ({target}) => {
        reportedData.duration = parseFloat(target.value);
        if (considerIrrigationRate){
            reportedData.value = parseFloat(target.value * irrigationRate);
        }
        setReportedData({...reportedData});
    }

    const handleAmountChange = ({target}) => {
        reportedData.value = parseFloat(target.value);
        if (considerIrrigationRate){
            reportedData.duration = parseFloat(target.value / irrigationRate).toFixed(3)
        }
        setReportedData({...reportedData});
    }

    return(
          <div className='d-flex mb-3'>
            <div className='modal-input-container'>
              <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{t('duration')} ({durationUnits})</div>
              <input type="number" min="0" step="0.5" placeholder="0" onChange={handleDurationChange} value={reportedData.duration || ''} disabled={durationDisabled}
                     className={"form-control border text-grey45 font-weight-semi-bold p-1 pl-2" + durationStyle} style={{borderRadius:5, height:40, width:'100%'}} />
            </div>
            <div style={{width:8, flex:"none"}}/>
            <div className='modal-input-container'>
              <div className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>{upper(t('amount'))} ({amountUnits})</div>
              <input type="number" min="0" step="0.01" placeholder="0" onChange={handleAmountChange} value={1*parseFloat(reportedData.value)?.toFixed(3) || ''} disabled={amountDisabled}
                     className={"form-control border text-grey45 font-weight-semi-bold p-1 pl-2" + amountStyle} style={{borderRadius:5, height:40, width:'100%'}} />
            </div>
          </div>
    )
}

const DischargeSection = ({t, data, setData}) => {

    const [testsArr, setTestsArr] = useState([{value: "0.00"}, {value: "0.00"}, {value: "0.00"}, {value: "0.00"}])
    const [avgTime, setAvgTime] = useState(0);
    const [avgDischarge, setAvgDischarge] = useState(0);
    const [displayAlert, setDisplayAlert] = useState(false);
    const selectedDistribution = useSelector((state) => state.distribution_data.selected_entities.distribution);
    const selectedField = useSelector((state) => state.distribution_data.selected_entities.field);
    const selectedDistributionName = selectedDistribution?.name;
    const selectedFieldTankType = selectedField?.irrigation_system?.tank_type;

    const handleTimeChange = (changedValue, index) => {
        setTestsArr((prevState) => {
            return prevState.map((test, i) => {
                return i === index ? {...test, value: changedValue} : test
            })
        })
    }

    useEffect(() => {
        mixpanel.track("Discharge Report Event Dialog Load");
    }, [])

    useEffect(() => {
        let activeTests = testsArr.filter((test) => {
            return test.value > 0
        });
        if (activeTests.length === 0) {
            setAvgTime(0);
            setAvgDischarge(0);
            setDisplayAlert(false);
            return;
        }
        let sum = activeTests.reduce((a, b) => a + b.value, 0);
        let avg = parseFloat((sum / activeTests.length).toFixed(3)).toLocaleString("en", { minimumFractionDigits: 1 });
        setAvgTime(avg);
        let avgDischarge = calculateDischargeRate(avg,selectedDistributionName, selectedFieldTankType);
        setAvgDischarge(avgDischarge.toLocaleString("en", { minimumFractionDigits: 1 }));
        if (selectedField.historical_data.hasOwnProperty("discharge") && selectedField.historical_data.discharge.length > 0) {
            let testsExtraData = calculateDischargeDeviation({value: avgDischarge}, selectedField.historical_data?.discharge);
            if (testsExtraData[selectedField.historical_data?.discharge.length]["relationDiff"] < DISCHARGE_ALERT_THRESHOLD) {
                setDisplayAlert(true);
            } else {
                setDisplayAlert(false);
            }
        }
        setData(prev => ({...prev, value:avgDischarge, activeTests:activeTests.length}));
    }, [testsArr, selectedDistributionName])

    useEffect(()=>{
        setTestsArr([{value: "0.00"}, {value: "0.00"}, {value: "0.00"}, {value: "0.00"}]);
        setAvgDischarge(0)
        setAvgTime(0);
        setData({});
    }, [selectedField])

    return (
        <div className='d-flex mb-3' style={{flexDirection: "column"}}>
            <div style={{display: "flex", flexDirection: "row"}}>
                {['', '', '', ''].map((value, index) => {
                    let placeholder = testsArr[index]?.value === "0.00" ? undefined :testsArr[index]?.value;
                    return (
                        <div style={{display: "flex", flexDirection: "column", marginRight: "8px"}}>
                            <div
                                className='text-grey45 text-size-14 font-weight-semi-bold mt-2 mb-1'>Test {index + 1} (sec)
                            </div>
                            <input type="number" min="0" step="0.5" placeholder="0.00" value={placeholder}
                                   onChange={(event) => {
                                       handleTimeChange(parseFloat(event.target.value), index)
                                   }}
                                   className={"form-control placeholder border text-grey45 text-size-16 p-1 pl-2"}
                                   style={{borderRadius: 5, height: 40, fontWeight: "400"}}/>
                        </div>
                    )
                })}
            </div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "32px"
            }}>
                <div className="text-grey71 text-size-16" style={{fontWeight: "400", display: "flex", gap: "4px"}}>
                    {t("average")}
                    <div className="text-grey45 text-size-16" style={{fontWeight: "700"}}>{avgTime}</div>
                    <div className="text-grey71 text-size-16"
                         style={{fontWeight: "400", marginRight: "8px"}}>{t("seconds")}</div>
                    <div className="text-grey91 text-size-16" style={{fontWeight: "600", marginRight: "8px"}}>|</div>
                    <div className="text-grey45 text-size-16"
                         style={{fontWeight: "700"}}>{t("discharge")} {avgDischarge}</div>
                    <div className="text-grey71 text-size-16" style={{fontWeight: "400", marginRight:4}}>(m³/h)</div>
                    {displayAlert && <img data-toggle="tooltip" data-container="body" id="report_discharge_alert_icon"
                                     data-placement="top" onMouseOver={() => {$(function () {$('#report_discharge_alert_icon').tooltip('show');})}}
                          title={t("discharge_result_exceeding_hint")}
                          src={require('images/icons/red_alert_icon.svg')}/>}
                    <div className="dropdown-divider"/>
                </div>
            </div>
        </div>
    )
}

function FootnoteSection({t, data, setData}) {

    const handleAmountChange = ({target}) => setData({...data, value: target.value});

    return (
        <div>
        <textarea type="text" maxLength="120" onChange={handleAmountChange} value={data?.value} placeholder={t('planning_note_placeholder')} style={{width: 370, borderRadius: 5}}
                  className="form-control border border-grey91 bg-grey98 text-grey45 align-text-top align-self-center font-weight-semi-bold align-items-center justify-content-between p-2"/>
        </div>
    );
}