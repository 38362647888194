var React = require("react");
var { connect } = require("react-redux");
var distributionActions = require("distributionActions");
var timezoneManager = require("timezoneManager");
const DayPickerInput = require("./DayPickerInput");
var GeneralClasses = require("GeneralClasses");
var moment = require("moment");
var { upper, sort_dictioanry_by_value } = require("helpers");
var env = require("env");
var { unitDisplayName, convert } = require("unitsManager");
var sensorsManager = require("sensorsManager");
var fieldManager = require("fieldManager");
var KCBox = require("KCBox");
var CoupModelBox = require("CoupModelBox");
var CustomMap = require("CustomMap");
var { withTranslation } = require("react-i18next");
var $ = require("jquery");
var SensorsWrapper = require("SensorsWrapper");
var IrrigationSystem = require("IrrigationSystem");
var IrrigationTimeFrame = require("IrrigationTimeFrame");
var Fertigation = require("Fertigation");
const WaterTreatmentComponent = require('WaterTreatmentComponent');
var {get_configuration_config, FeatureFlagDictionary} = require('remoteConfigurationsManager');

var FARMING_TYPES_DICTIONARY = {
  open_field: "Open field",
  tunnel: "Tunnel",
  greenhouse: "Greenhouse",
  net_house: "Net house",
  lab: "Lab",
  net_house_tali_grapes: "Net house Tali grapes",
  net_house_meshek_malka: "Net house Meshek Malka",
  thermal_blanket: "Thermal Blanket",
};

const UpdateFieldModal = (props) => {
  const {
    userData,
    field_actions,
    soil_data,
    selected_farming_unit,
    selected_distribution,
    configuration_data,
    selected_field,
    create_field,
    update_field,
    clear_field_action,
    t,
    modalId,
  } = props;

  const [state, setState] = React.useState({
    title: "",
    action: "",
    invalid_map_coords_message: "",
    current_field: new GeneralClasses.Field(),
    areaSize: "",
    soil_type: "",
  });

  const timezones = timezoneManager.get_timezones();
  const timezone_array_sorted = sort_dictioanry_by_value(timezones);

  const field_name = React.useRef(null);
  const formRef = React.useRef(null);
  const soil_type_field = React.useRef(null);
  const cluster_ref = React.useRef(null);
  const custom_map = React.useRef(null);

  React.useEffect(() => {
    const handleShownBsModal = (event) => {
      let action_type = "";

      if (event.relatedTarget) {
        let button = $(event.relatedTarget);
        action_type = button[0].dataset?.data;
      }

      if (
        action_type === "duplicate" ||
        state.action === "Update" ||
        state.action === t("update") ||
        action_type === "activate"
      ) {
        custom_map.current.invalidate(
          {
            [state.current_field.id]: {
              areas: state.current_field.geo_data.areas,
              title: field_name.current.value,
              fill_color: "#FFFFFF",
            },
          },
          {},
          {},
          true
        );
      } else {
        custom_map.current.invalidate({}, {}, {}, true);
      }
    };

    const handleShowBsModal = (event) => {
      formRef.current.classList.remove("was-validated");

      let field_id = "";
      let action_type = "";
      let selected_field = null;

      if (event.relatedTarget) {
        const button = $(event.relatedTarget); // Button that triggered the modal
        field_id = button[0].dataset.id; // Extract info from data-* attributes
        action_type = button[0].dataset?.data; // check if duplicate/activate is pressed?
      }

      if (field_id === "") {
        selected_field = new GeneralClasses.Field();

        setState((prev) => ({
          ...prev,
          title: t("create"),
          action: t("create"),
          invalid_map_coords_message: "",
          current_field: selected_field,
          soil_type: selected_field.soil_type,
        }));
      } else {
        // copy object
        if (selected_farming_unit.fields) {
          selected_field = Object.assign(
            {},
            selected_farming_unit.fields[field_id]
          );
        }

        let areaSize = calculateArea(selected_field.geo_data.areas);

        if (
          selected_field.geo_data.area_size > 0 &&
          selected_field.geo_data.area_size !== areaSize
        ) {
          areaSize = selected_field.geo_data.area_size;
        }

        setState((prev) => {
          return {
            ...prev,
            areaSize: areaSize,
            title:
              action_type === "duplicate" || action_type === "activate"
                ? t("create")
                : t("update"),
            action:
              action_type === "duplicate" || action_type === "activate"
                ? t("create")
                : t("update"),
            invalid_map_coords_message: "",
            current_field: selected_field,
            soil_type: selected_field.soil_type,
          };
        });
      }

      if (action_type === "duplicate") {
        field_name.current.value = "Copy of " + selected_field.name;

        setState((prevState) => {
          return {
            ...prevState,
            current_field: {
              ...prevState.current_field,
              initial_concentrations: {},
              irrigation_system: {
                ...prevState.current_field.irrigation_system,
                fertigation_concentrations: [],
              },
              contract_data: {
                ...prevState.current_field.contract_data,
                installation_date: moment().unix(),
                expiration_date: moment().add(1, "years").unix(),
              },
            },
          };
        });
      } else if (action_type === "activate") {
        field_name.current.value = selected_field.name;

        setState((prevState) => ({
          ...prevState,
          current_field: {
            ...prevState.current_field,
            settings: {
              ...prevState.current_field.settings,
              field_state: "active",
            },
          },
        }));
      } else {
        field_name.current.value = selected_field.name;
      }
    };

    const handleHiddenBsModal = () => {
      custom_map.current.resetMap();
      clear_field_action();
    };

    $("#" + modalId).on("shown.bs.modal", handleShownBsModal);
    $("#" + modalId).on("show.bs.modal", handleShowBsModal);
    $("#" + modalId).on("hidden.bs.modal", handleHiddenBsModal);

    return () => {
      $("#" + modalId).off("shown.bs.modal", handleShownBsModal);
      $("#" + modalId).off("show.bs.modal", handleShowBsModal);
      $("#" + modalId).off("hidden.bs.modal", handleHiddenBsModal);
    };
  }, [
    modalId,
    formRef.current,
    selected_farming_unit,
    field_name.current,
    custom_map.current,
    state.current_field.geo_data.areas,
  ]);

  const autoFill = () => {
    field_name.current.value = "Auto generated field " + moment().unix();
    soil_type_field.current.value = "Sand";

    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        crop_data: {
          ...prevState.current_field.crop_data,
          kc_table: {
            type: "byGrowthPhase",
            data: {
              byGrowthPhaseGDD: [
                { kc: 1.15, name: "dummy phase", is_dev: false, duration: 30 },
                { is_dev: true, duration: 10 },
                { kc: 0.9, name: "late", is_dev: false, duration: 70 },
              ],
              byGrowthPhase: [
                { kc: 1.15, name: "dummy phase", is_dev: false, duration: 30 },
                { is_dev: true, duration: 10 },
                { kc: 0.9, name: "late", is_dev: false, duration: 70 },
              ],
              byMonth: {
                jan: "1.01",
                feb: "1.02",
                mar: "1.15",
                apr: "1.15",
                may: "1.16",
                jun: "1.20",
                jul: "1.03",
                aug: "1.03",
                sep: "1.00",
                oct: "0.77",
                nov: "0.76",
                dec: "0.56",
              },
            },
          },
          sow_date: moment(1552214813333).format("YYYY-MM-DD"),
        },
        contract_data: {
          ...prevState.current_field.contract_data,
          installation_date: moment().unix(),
          expiration_date: moment().add(3, "years").unix(),
        },
      },
    }));
  };

  const calculateArea = (areas) => {
    // calculate area
    fieldArea = fieldManager.getFieldArea(areas);
    fieldAreaConverted = convert(fieldArea, "dunam", userData.area, "area");

    return fieldAreaConverted;
  };

  const onAreaChanged = (areas) => {
    let message = { ...state }.invalid_map_coords_message;
    let area_size = calculateArea(areas);

    if (areas && areas.length > 0) {
      message = "";
    }

    setState((prevState) => ({
      ...prevState,
      areaSize: area_size,
      invalid_map_coords_message: message,
      current_field: {
        ...prevState.current_field,
        geo_data: {
          ...prevState.current_field.geo_data,
          areas: areas,
          area_size: area_size,
          agriapi_polygon_ids: [],
        },
      },
    }));
  };

  const onFieldSizeChange = (newFieldSize) => {
    setState((prevState) => ({
      ...prevState,
      areaSize: newFieldSize,
      current_field: {
        ...prevState.current_field,
        geo_data: {
          ...prevState.current_field.geo_data,
          area_size: newFieldSize,
        },
      },
    }));
  };

  const handleSensorsChange = (newSensorsObject) => {
    setState((prevState) => {
      return {
        ...prevState,
        current_field: {
          ...prevState.current_field,
          sensors: newSensorsObject,
        },
      };
    });
  };

  const handleWFRSensorsChange = (newSensorsObject) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        wfrsensors: newSensorsObject,
      },
    }));
  };

  const handleKCTableChange = (newKCTable) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        crop_data: {
          ...prevState.current_field.crop_data,
          kc_table: newKCTable,
        },
      },
    }));
  };

  const handleSoilTypeChange = (newSoilType) => {
    setState((prevState) => ({
      ...prevState,
      soil_type: newSoilType,
      current_field: {
        ...prevState.current_field,
        soil_type: newSoilType,
      },
    }));
  };

  const handleFarmingTypeChange = (newFarmingType) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        farming_type: newFarmingType,
      },
    }));
  };

  const handleSoilConcentrationChange = (concentrations) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        initial_concentrations: concentrations,
      },
    }));
  };

  const handleFertigationConcentrationChange = (fcs) => {
    let fcsPlain = fcs.map((currentItem) => {
      return {
        start_date: currentItem.startDate.format("YYYY-MM-DD"),
        fertType: currentItem.fertType,
        concentration: parseFloat(currentItem.concentration),
      };
    });

    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        irrigation_system: {
          ...prevState.current_field.irrigation_system,
          fertigation_concentrations: fcsPlain,
        },
      },
    }));
  };

  const handleFertigationPlanChange = (fertigationPlan) => {
    console.log(fertigationPlan);
  };

  const handleAutomateIrrigation = (shouldAutomateState) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        settings: {
          ...prevState.current_field.settings,
          coup_settings: {
            should_automate_irrigations: shouldAutomateState,
          },
        },
      },
    }));
  };

  const handleCoupModelSelection = (coupModelState) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        settings: {
          ...prevState.current_field.settings,
          is_coupmodel_active: coupModelState,
        },
      },
    }));
  };

  const handleCropDetailsChange = (crop_type, crop_variety, crop_feature) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        crop_data: {
          ...prevState.current_field.crop_data,
          crop_type: crop_type,
          crop_variety: crop_variety,
          crop_feature: crop_feature,
        },
      },
    }));
  };

  const handleSowDateChange = (formattedDate) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        crop_data: {
          ...prevState.current_field.crop_data,
          sow_date: formattedDate,
        },
      },
    }));
  };

  const handleIrrigationDateChange = (formattedDate) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        crop_data: {
          ...prevState.current_field.crop_data,
          irrigation_start_date: formattedDate,
        },
      },
    }));
  };

  const handleHarvestDateChange = (formattedDate) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        crop_data: {
          ...prevState.current_field.crop_data,
          harvest_date: formattedDate,
        },
      },
    }));
  };

  const handleNewExpirationDay = (day) => {
    let newExpirationData = moment(day).unix();

    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        contract_data: {
          ...prevState.current_field.contract_data,
          expiration_date: newExpirationData,
        },
      },
    }));
  };

  const handleRollingFitChange = (event) => {
    const rollingFitSupported = event.target.checked;

    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        settings: {
          ...prevState.current_field.settings,
          is_rolling_fit_active: rollingFitSupported,
        },
      },
    }));
  };

  const handleIrrigationSystemTypeChange = (type) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        irrigation_system: {
          ...prevState.current_field.irrigation_system,
          irrigation_type: type,
        },
      },
    }));
  };
  const handleTankTypeChange = (tankType) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        irrigation_system: {
          ...prevState.current_field.irrigation_system,
          tank_type: tankType,
        },
      },
    }));
  };
  const handleMeasuredRateStatusChange = (status) => {
    setState((prevState) => ({
      current_field: {
        ...prevState.current_field,
        settings: {
          ...prevState.current_field.settings,
          is_measured_rate_active: status,
        },
      },
    }));
  };
  const handleIrrigationSystemRateChange = (rate) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        irrigation_system: {
          ...prevState.current_field.irrigation_system,
          irrigation_rate: rate,
        },
      },
    }));
  };

  const handleIrrigationSystemHinChange = (hin) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        irrigation_system: {
          ...prevState.current_field.irrigation_system,
          irrigation_hin: hin,
        },
      },
    }));
  };

  const handleIrrigationTimeFrameChange = (timeFrames) => {
    setState((prevState) => ({
      ...prevState,
      current_field: {
        ...prevState.current_field,
        irrigation_system: {
          ...prevState.current_field.irrigation_system,
          time_frames: timeFrames,
        },
      },
    }));
  };

  const onSubmitClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let form = formRef.current;
    let should_submit = form.checkValidity();
    let current_field = { ...state.current_field };

    // self checks for map values
    if (
      !current_field.geo_data.areas ||
      (current_field.geo_data.areas && current_field.geo_data.areas.length === 0)
    ) {
      setState((prev) => ({
        ...prev,
        invalid_map_coords_message: t("edit_field_choose_area_message"),
      }));
      should_submit = false;
    } else {
      setState((prev) => ({ ...prev, invalid_map_coords_message: "" }));
    }
    // Local check (given by bootstrap)
    if (should_submit) {
      current_field.name = field_name.current.value;
      current_field.cluster.id = cluster_ref.current.value;
      current_field.soil_type = { ...state }.soil_type;

      if (state.action === t("create")) {
        current_field.sensors = sensorsManager.serialNumberCheck(
          current_field.sensors
        );
        create_field(
          selected_distribution.id,
          selected_farming_unit.id,
          current_field
        );
      } else if (state.action === t("update")) {
        update_field(
          selected_distribution.id,
          selected_farming_unit.id,
          current_field
        );

        // check for changes when update field to send the mixpanel
        let changes = checkValuesChangesInField();
        if (Object.keys(changes).length > 0) {
          mixpanel.track("Field Update (Event property)", { Changes: changes });
        }
      }
    }

    form.classList.add("was-validated");
  };

  const shouldDisableCropType = (installationDate) => {
    const today = moment();
    const installDay = moment(installationDate);
    const duration = moment.duration(today.diff(installDay)).asDays();

    return duration > 60;
  };

  const onLayerSelection = (layerName) => {};

  const checkValuesChangesInField = () => {
    const copyOfCurrent_field = { ...state }.current_field;
    const copyOfSelected_field = { ...selected_field };
    let changes = {};
    let keysToCheck = ["irrigation_system", "settings", "crop_data"];

    for (let key of keysToCheck) {
      if (key === "settings") {
        if (
          copyOfCurrent_field[key].is_rolling_fit_active !==
          copyOfSelected_field[key].is_rolling_fit_active
        ) {
          changes["is_planner_active"] =
            copyOfCurrent_field[key].is_rolling_fit_active;
        }
      } else if (key === "crop_data") {
        if (
          copyOfCurrent_field[key].sow_date !==
          copyOfSelected_field[key].sow_date
        ) {
          changes["sow_date"] = copyOfCurrent_field[key].sow_date;
        }
      } else if (key === "irrigation_system") {
        if (
          copyOfCurrent_field[key].irrigation_rate !==
          copyOfSelected_field[key].irrigation_rate
        ) {
          changes["old_irrigation_rate"] =
            copyOfSelected_field[key].irrigation_rate;
          changes["new_irrigation_rate"] =
            copyOfCurrent_field[key].irrigation_rate;
        }

        if (
          copyOfCurrent_field[key].irrigation_type !==
          copyOfSelected_field[key].irrigation_type
        ) {
          changes["irrigation_type"] = copyOfCurrent_field[key].irrigation_type;
        }
      }
    }

    return changes;
  };

  let is_moderator = selected_farming_unit?.role === "moderator";

  if (
    field_actions.status === "success" &&
    ["UPDATE", "CREATE"].includes(field_actions.type)
  ) {
    $("#" + modalId).modal("hide");
  }

  var installation_date =
    state.current_field && state.current_field.contract_data.installation_date
      ? moment
          .unix(state.current_field.contract_data.installation_date)
          .format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");
  var expiration_date =
    state.current_field && state.current_field.contract_data.expiration_date
      ? moment
          .unix(state.current_field.contract_data.expiration_date)
          .format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD");

    const measuredIrrigationRateObj = { ...state }.current_field?.historical_data?.measured_irrigation_rate;
    let measuredIrrigationRate = measuredIrrigationRateObj ?  Object.values(measuredIrrigationRateObj.values)[0] : null;
    const featureFlag = get_configuration_config(configuration_data, FeatureFlagDictionary["WT_PROTOCOL"], selected_farming_unit, selected_distribution);
    const isNdripIrrigationType = state.current_field.irrigation_system.irrigation_type === "ndrip";

  return (
    <div
      className="modal fade mb-5 pb-5 text-grey45"
      id={modalId}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <form
        className="needs-validation"
        noValidate
        onSubmit={onSubmitClick}
        id="distribution-form"
        style={{ height: "100%" }}
        ref={formRef}
      >
        <div
          className="modal-dialog modal-lg modal-dialog-centered"
          role="document"
          style={{ top: 10, maxHeight: "calc(100vh - 70px)", height: "100%" }}
        >
          <div
            className="modal-content bg-grey98"
            style={{ alignSelf: "stretch" }}
          >
            <div
              className="modal-header"
              style={{ background: "white", height: "72px" }}
            >
              <h5
                className="modal-title ml-2 font-weight-bold text-size-32"
                style={{ lineHeight: "normal" }}
                id="exampleModalLabel"
              >
                {state.title} {t("field")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{
                  color: "#4D5674",
                  opacity: 1,
                  outline: "none",
                  padding: "24px",
                }}
              >
                <img src={require("images/icons/edit_field/close_icon.svg")} />
              </button>
            </div>
            <div
              className="modal-body pt-0 pb-0"
              style={{ overflowY: "auto", flex: 1, height: "100%" }}
            >
              <div className="form-row justify-content-center">
                <div className="col">
                  <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                    <label style={{ marginBottom: "24px" }} htmlFor="fieldName">
                      {t("edit_field_field_name")}
                    </label>
                    <input
                      type="text"
                      ref={field_name}
                      className="form-control col-sm-4"
                      placeholder={t("edit_field_field_name")}
                      required
                    />
                    <div className="invalid-feedback">
                      {t("edit_field_field_name_invalid_data_message")}
                    </div>
                  </div>
                </div>
              </div>
              {!is_moderator && (
                <div className="form-row justify-content-center">
                  <div className="col-12">
                    <div
                      className="ml-2 mr-2 mt-1 font-weight-bold text-size-20"
                      style={{ pointerEvents: is_moderator ? "none" : "auto" }}
                    >
                      <label style={{ marginBottom: "16px" }} htmlFor="sensors">
                        {t("area")}
                      </label>
                      <CustomMap
                        mapId="edit_field"
                        showEditToolBar={true}
                        onRef={(ref) => (custom_map.current = ref)}
                        map_height="400"
                        onAreaChanged={onAreaChanged}
                        onLayerSelection={onLayerSelection}
                        message={state.invalid_map_coords_message}
                        shouldPresentStressMenu={false}
                        shouldPresentFilePicker={true}
                        shouldPresentPlusButton={false}
                      />
                      <div className="d-flex">
                        <div className="mr-auto">
                          <small className="form-text text-grey71 text-size-14">
                            {t("edit_field_area_invalid_data_message")}
                          </small>
                        </div>
                        <div>
                          <small className="form-text text-grey71 text-size-14"></small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="form-row ">
                <div className="col-12 col-lg-4">
                  <div
                    className="form-group ml-1 mt-3 font-weight-semi-bold text-size-14"
                    style={{ marginRight: "4px" }}
                  >
                    <label htmlFor="soilType" style={{ marginBottom: "4px" }}>
                      {t("soil_type")}
                    </label>
                    {
                      <select
                        defaultValue={
                          t(state.soil_type).toLowerCase() ||
                          t(state.current_field.soil_type).toLowerCase()
                        }
                        value={
                          t(state.soil_type).toLowerCase() ||
                          t(state.current_field.soil_type).toLowerCase()
                        }
                        onChange={(e) => {
                          handleSoilTypeChange(e.target.value);
                        }}
                        className="form-control custom-select"
                        id="exampleFormControlSelect1"
                        style={{ background: "none" }}
                      >
                        {soil_data &&
                          soil_data.soil_data &&
                          Object.keys(soil_data.soil_data)
                            .filter(
                              (item) =>
                                soil_data.soil_data[item].active === true
                            )
                            .sort()
                            .map((item) => {
                              return (
                                <option value={item} key={item}>
                                  {t(upper(item))}
                                </option>
                              );
                            })}
                      </select>
                    }
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div
                    className="form-group ml-1 mt-3 font-weight-semi-bold text-size-14"
                    style={{ marginRight: "4px", marginLeft: "4px" }}
                  >
                    <label
                      htmlFor="farming_type"
                      style={{ marginBottom: "4px" }}
                    >
                      {t("farming_type")}
                    </label>
                    <select
                      value={
                        Object.keys(FARMING_TYPES_DICTIONARY).filter(
                          (farming_type) => {
                            return (
                              farming_type === state.current_field.farming_type
                            );
                          }
                        )[0]
                      }
                      onChange={(e) => {
                        handleFarmingTypeChange(e.target.value);
                      }}
                      className="form-control custom-select"
                      id="exampleFormControlSelect1"
                      style={{ background: "none" }}
                    >
                      {Object.values(FARMING_TYPES_DICTIONARY)
                        .sort()
                        .map((item) => {
                          let key = Object.keys(
                            FARMING_TYPES_DICTIONARY
                          ).filter(function (key) {
                            return FARMING_TYPES_DICTIONARY[key] === item;
                          })[0];
                          return (
                            <option value={key} key={key}>
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-12 col-lg-4">
                  <div className="form-group ml-1 mr-2 mt-3 font-weight-semi-bold text-size-14">
                    <label htmlFor="fieldSize" style={{ marginBottom: "4px" }}>
                      {t("field_size") + ` (${unitDisplayName(userData.area)})`}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="field_size"
                      onChange={(event) => {
                        onFieldSizeChange(event.target.value);
                      }}
                      value={state.areaSize}
                      disabled={is_moderator}
                    />
                  </div>
                </div>
              </div>

              <div className="form-row justify-content-center">
                <div className="col">
                  <KCBox
                    selectedField={state.current_field}
                    modalId={modalId}
                    onKCTableChange={handleKCTableChange}
                    onCropDetailsChange={handleCropDetailsChange}
                    onIrrigationDateChange={handleIrrigationDateChange}
                    onSowDateChange={handleSowDateChange}
                    onHarvestDateChange={handleHarvestDateChange}
                    disableCropType={false}
                    isModerator={is_moderator}
                  />
                </div>
              </div>
              {!is_moderator && (
                <IrrigationSystem
                  onMeasuredRateStatusChange={handleMeasuredRateStatusChange}
                  onTankTypeChange={handleTankTypeChange}
                  onIrrigationSystemTypeChange={
                    handleIrrigationSystemTypeChange
                  }
                  onIrrigationSystemRateChange={
                    handleIrrigationSystemRateChange
                  }
                  onNDripHinChange={(hin) =>
                    handleIrrigationSystemHinChange(hin)
                  }
                  initialHin={
                    state.current_field.irrigation_system.irrigation_hin
                  }
                  initialRate={
                    state.current_field.irrigation_system.irrigation_rate
                  }
                  initialType={
                    state.current_field.irrigation_system.irrigation_type
                  }
                  initialMeasuredRate={measuredIrrigationRate}
                  initialTankType={
                    state.current_field.irrigation_system?.tank_type
                  }
                  initialMeasuredRateStatus={
                    state.current_field.settings?.is_measured_rate_active
                  }
                ></IrrigationSystem>
              )}
              {isNdripIrrigationType && featureFlag &&
                  <WaterTreatmentComponent location={"field_modal"}
                                           currentFarmingUnit={selected_farming_unit} shouldDisplayAsDisabled={true}/>
              }
              {!is_moderator && (
                <div className="form-row justify-content-center">
                  <div className="col-12">
                    <div className="form-group ml-2 mr-2 mt-3">
                      <label
                        className="font-weight-bold text-size-20"
                        htmlFor="planner"
                      >
                        {t("Planner")}
                      </label>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          checked={
                            state.current_field.settings.is_rolling_fit_active
                          }
                          onChange={(event) => {
                            handleRollingFitChange(event);
                          }}
                          style={{ marginTop: "0.4rem" }}
                          type="checkbox"
                          value=""
                          id="planner"
                        />
                        <label className="text-grey45" htmlFor="planner">
                          {t("support_planner")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {state.current_field.settings.is_rolling_fit_active ? (
                <IrrigationTimeFrame
                  initialTimeFrames={
                    state.current_field.irrigation_system.time_frames
                  }
                  onIrrigationTimeFramesChange={handleIrrigationTimeFrameChange}
                  fieldIrrigationSystem={state.current_field.irrigation_system}
                  fieldSoilType={state.current_field.soil_type}
                  irrigationStartDate={
                    state.current_field?.crop_data?.irrigation_start_date ||
                    state.current_field?.crop_data?.sow_date
                  }
                ></IrrigationTimeFrame>
              ) : null}
              {!is_moderator && (
                <SensorsWrapper
                  selectedField={state.current_field}
                  onSensorsChange={handleSensorsChange}
                />
              )}

              <Fertigation
                initial_concentrations={
                  state.current_field.initial_concentrations
                }
                isModerator={is_moderator}
                onFertigationConcentrationChange={
                  handleFertigationConcentrationChange
                }
                onConcentrationChange={handleSoilConcentrationChange}
                initialFertigationConcentrations={
                  state.current_field.irrigation_system
                    .fertigation_concentrations
                }
              />

              {!is_moderator && (
                <CoupModelBox
                  selectedField={state.current_field}
                  modalId={props.modalId}
                  onCoupModelSelection={handleCoupModelSelection}
                  onAutomateIrrigationSelection={handleAutomateIrrigation}
                />
              )}

              <div className="form-row justify-content-start">
                <div className="col-4">
                  <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                    <label htmlFor="fieldName">{t("cluster")}</label>
                    <select
                      className="form-control custom-select"
                      ref={cluster_ref}
                      id="exampleFormControlSelect1"
                    >
                      <option
                        value={""}
                        key={0}
                        selected={
                          state.current_field.cluster.id == "" ? "selected" : ""
                        }
                      >
                        {t("none_default")}{" "}
                      </option>
                      <option
                        value={"A"}
                        key={1}
                        selected={
                          state.current_field.cluster.id == "A"
                            ? "selected"
                            : ""
                        }
                      >
                        A
                      </option>
                      <option
                        value={"B"}
                        key={2}
                        selected={
                          state.current_field.cluster.id == "B"
                            ? "selected"
                            : ""
                        }
                      >
                        B
                      </option>
                      ;
                      <option
                        value={"C"}
                        key={3}
                        selected={
                          state.current_field.cluster.id == "C"
                            ? "selected"
                            : ""
                        }
                      >
                        C
                      </option>
                      ;
                      <option
                        value={"D"}
                        key={4}
                        selected={
                          state.current_field.cluster.id == "D"
                            ? "selected"
                            : ""
                        }
                      >
                        D
                      </option>
                      ;
                      <option
                        value={"E"}
                        key={5}
                        selected={
                          state.current_field.cluster.id == "E"
                            ? "selected"
                            : ""
                        }
                      >
                        E
                      </option>
                      ;
                      <option
                        value={"F"}
                        key={6}
                        selected={
                          state.current_field.cluster.id == "F"
                            ? "selected"
                            : ""
                        }
                      >
                        F
                      </option>
                      ;
                      <option
                        value={"G"}
                        key={7}
                        selected={
                          state.current_field.cluster.id == "G"
                            ? "selected"
                            : ""
                        }
                      >
                        G
                      </option>
                      ;
                      <option
                        value={"H"}
                        key={8}
                        selected={
                          state.current_field.cluster.id == "H"
                            ? "selected"
                            : ""
                        }
                      >
                        H
                      </option>
                      ;
                      <option
                        value={"I"}
                        key={9}
                        selected={
                          state.current_field.cluster.id == "I"
                            ? "selected"
                            : ""
                        }
                      >
                        I
                      </option>
                      ;
                      <option
                        value={"J"}
                        key={10}
                        selected={
                          state.current_field.cluster.id == "J"
                            ? "selected"
                            : ""
                        }
                      >
                        J
                      </option>
                      ;
                      <option
                        value={"K"}
                        key={11}
                        selected={
                          state.current_field.cluster.id == "K"
                            ? "selected"
                            : ""
                        }
                      >
                        K
                      </option>
                      ;
                    </select>
                  </div>
                </div>
              </div>

              {!is_moderator && (
                <div className="form-row justify-content-center">
                  <div className="col-12 col-lg-6">
                    <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                      <label htmlFor="fieldName">
                        {t("edit_field_installation_date")}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="installation_date_input"
                        aria-describedby="installationHelp"
                        placeholder="Installation Date"
                        required
                        disabled
                        value={installation_date}
                      />
                      <div className="invalid-feedback">
                        {t("edit_field_installation_date_invalid_message")}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="form-group ml-2 mr-2 mt-3 font-weight-bold text-size-20">
                      <label
                        className="font-weight-bold"
                        htmlFor="contract_end_date_input"
                      >
                        {t("edit_field_contract_duration")}
                      </label>
                      <br />
                      <DayPickerInput
                        value={expiration_date}
                        onChange={handleNewExpirationDay}
                        inputProps={{
                          id: "contract_end_date_input",
                        }}
                      />
                      <div className="invalid-feedback">
                        {t("edit_field_contract_duration_invalid_message")}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="form-row justify-content-center mt-4">
                <div className="text-red text-center">
                  {field_actions.status == "fail" && field_actions.error}
                </div>
              </div>
            </div>

            <div className="modal-footer" style={{ background: "white" }}>
              <div className="container text-center">
                {env.stage == "local" && (
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={autoFill}
                  >
                    Auto Fill
                  </button>
                )}

                <button
                  type="button"
                  className="btn m-1 p-0 btn-lg footer-button-cancel"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  {t("cancel")}
                </button>

                <button
                  type="submit"
                  className="btn m-0 p-0 btn-lg footer-button-save text-white"
                  disabled={
                    ["CREATE", "UPDATE"].includes(field_actions.type) &&
                    field_actions.status === "init"
                  }
                >
                  {state.title}

                  {["CREATE", "UPDATE"].includes(field_actions.type) &&
                    field_actions.status === "init" && (
                      <span>
                        &nbsp;&nbsp;
                        <span
                          className="spinner-grow spinner-grow-sm mb-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </span>
                    )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    userData: state.authentication_data.user_data,
    field_actions: state.distribution_data.field_actions,
    soil_data: state.soil_data.soil_data,
    selected_farming_unit: state.distribution_data.selected_entities.farming_unit,
    selected_distribution: state.distribution_data.selected_entities.distribution,
    selected_field: state.distribution_data.selected_entities.field,
    configuration_data: state.configuration_data,
  };
};

const mapDispatchToProps = {
  create_field: distributionActions.create_field,
  update_field: distributionActions.update_field,
  clear_field_action: distributionActions.clear_field_action,
};

module.exports = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(UpdateFieldModal));
